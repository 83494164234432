import { toast } from "react-toastify";
import Axios from "axios";

let toastDict = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

function handleNext(data, slide, cnfPass) {
  const fields = [
    ["fName", "dob", "gender"],
    ["email", "phone"],
    ["city", "state", "college", "level"],
    ["password"],
  ];

  // for dev purposes returned true
  // return true;

  if (slide < 3) {
    return !fields[slide].some((val) => !data[val]);
  } else {
    return data["password"] && cnfPass;
  }
}

function handlePass(data, cnfPass) {
  const pswd = /(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,20})$/;

  const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  if (data["password"].match(specialChar)) {
    toast.error("Password must only contain letters and numbers!", toastDict);
    return false;
  }

  if (data["password"].match(pswd)) {
    if (data["password"] === cnfPass) {
      return true;
    } else {
      toast.error("Passwords do not match!", toastDict);
      return false;
    }
  } else {
    toast.error(
      "Password must be atleast 8 characters long containing atleast one digit and no special characters.",
      toastDict
    );
    return false;
  }
}

async function reSendOTP(setLoading) {
  setLoading(true);
  await Axios.post(process.env.REACT_APP_BACKEND_URL + "/auth/resend-otp", {
    token: localStorage.getItem("nssclogin"),
  })
    .then((response) => {
      if (response.data.status === 200) {
        setLoading(false);
        toast.success(response.data.message, toastDict);
      } else if (response.data.status == 800 || response.data.status == 401) {
        toast.error(response.data.message, toastDict);
        setLoading(false);
      } else {
        toast.error("Something went wrong! Please try again.", toastDict);
        setLoading(false);
      }
    })
    .catch((error) => {
      toast.error(
        "Something went wrong! Please check your internet connection.",
        toastDict
      );
      setLoading(false);
    });
}

async function buttonHandler(
  slide,
  setSlide,
  data,
  cnfPass,
  setLoading,
  setIsRegister
) {
  if (handleNext(data, slide, cnfPass)) {
    if (slide === 3) {
      if (handlePass(data, cnfPass)) {
        setLoading(true);
        await Axios.post(
          process.env.REACT_APP_BACKEND_URL + "/auth/register",
          data
        )
          .then((response) => {
            if (response.data.status === 200) {
              setLoading(false);
              setSlide(5);
              toast.success(response.data.message, toastDict);
              localStorage.setItem("nssclogin", response.data.token);
            } else if (response.data.status == 801) {
              toast.error(response.data.message, toastDict);
              setLoading(false);
              setIsRegister(0);
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
              setLoading(false);
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
            setLoading(false);
          });
      }
    } else if (slide === 1) {
      if (confirm("Kindly confirm your email address: " + data.email)) {
        setSlide(slide + 1);
      }
    } else {
      setSlide(slide + 1);
    }
  } else {
    toast.error("Please fill all the required fields!", toastDict);
  }
}

async function checkOTP(otp, setSlide, setLoading) {
  if (!otp) {
    toast.error("Please enter the otp", toastDict);
    return false;
  }
  setLoading(true);
  await Axios.post(process.env.REACT_APP_BACKEND_URL + "/auth/verify-otp", {
    token: localStorage.getItem("nssclogin"),
    otp: otp,
  })
    .then((response) => {
      if (response.data.status === 200) {
        setLoading(false);
        toast.success(response.data.message, toastDict);
        setSlide(5);
      } else if (response.data.status == 800 || response.data.status == 401) {
        setLoading(false);
        toast.error(response.data.message, toastDict);
      } else {
        setLoading(false);
        toast.error("Something went wrong! Please try again.", toastDict);
      }
    })
    .catch((error) => {
      toast.error(
        "Something went wrong! Please check your internet connection.",
        toastDict
      );
      setLoading(false);
    });
}

async function loginHandler(data, setLoading, setSlide) {
  setLoading(true);
  await Axios.post(process.env.REACT_APP_BACKEND_URL + "/auth/login", {
    email: data.email,
    password: data.password,
  })
    .then((response) => {
      if (response.data.status === 200) {
        setLoading(false);
        toast.success(response.data.message, toastDict);
        localStorage.setItem("nssclogin", response.data.token);
        if (response.data.verified) setSlide(5);
        else setSlide(4);
      } else if (response.data.status == 800 || response.data.status == 401) {
        setLoading(false);
        toast.error(response.data.message, toastDict);
      } else {
        setLoading(false);
        toast.error("Something went wrong! Please try again.", toastDict);
      }
    })
    .catch((error) => {
      toast.error(
        "Something went wrong! Please check your internet connection.",
        toastDict
      );
      setLoading(false);
    });
}

export { handlePass, buttonHandler, reSendOTP, checkOTP, loginHandler };
