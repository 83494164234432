import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./css/App.css";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Team from "./pages/Team.js";
import Contact from "./pages/contact";
import Sponsors from "./pages/Sponsor1";
import Merchandise from "./pages/Merchandise";
import Merchandise2 from "./pages/Merchandise2";
import GuestLectures from "./pages/GuestLectures";
import Schedule3 from "./pages/Schedule";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import Login from "./pages/Login";
import Profile from "./pages/Profile.js";
import ForgotPassword from "./pages/ForgotPassword";
import ComingSoon from "./pages/ComingSoon.js";
import Navbar from "./components/Navbar1";
import EventDetailsData from "./data/events";
import LandingPage from "./pages/LandingPage";
import GuestLecturesDetails from "./pages/GuestLectureDetails";
import GuestLecturesData from "./data/guestlectures.json";
import Payment from "./pages/Payment";
import Redir from "./components/Redir";
// import Redirect from "./components/Redirect";
// import ParticlesBackground from "./components/ParticlesBackground";

function Home() {
  return (
    <div>
      <h1>Home Page</h1>
      <br />
      <Link to="/about">About</Link>
      <br />
      <Link to="/Landing">LandingPage</Link>
      <br />
      <Link to="/events">Events</Link>
      <br />
      <Link to="/guests">Guest Lectures and Workshops</Link>
      <br />
      <Link to="/gallery">Gallery</Link>
      <br />
      <Link to="/sponsors">Sponsors</Link>
      <br />
      <Link to="/team">Team</Link>
      <br />
      <Link to="/contact">Contact</Link>
      <br />
      <Link to="/Payment">Accomodation</Link>
      <br />
      <Link to="/guestlectures">Guest Lectures</Link>
      <br />
      <Link to="/register">Register</Link>
      <br />
      <Link to="/login">Login</Link>
      <br />
      <Link to="/profile">Profile Page</Link>
    </div>
  );
}
function App() {
  return (
    <Router>
      <Navbar />
      {/* <ParticlesBackground /> */}
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/events" element={<Events />} />
        {Object.keys(EventDetailsData).map((val) => {
          if (val === "14") return <React.Fragment key={val}></React.Fragment>;
          return (
            <Route
              key={val}
              path={EventDetailsData[val].path}
              element={<EventDetails eventId={val} />}
            />
          );
        })}
        <Route path="/login" element={<Login register={0} />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/register" element={<Login register={1} />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/explore" element={<Home />} /> */}
        {/* <Route path="/sponsors" element={<Sponsors />} /> */}
        {/* <Route path="/schedule" element={<Schedule3 />} /> */}
        <Route path="/guestlectures" element={<GuestLectures />} />
        {Object.keys(GuestLecturesData).map((val) => (
          <Route
            key={val}
            path={GuestLecturesData[val].path}
            element={<GuestLecturesDetails id={val} />}
          />
        ))}
        {/* <Route path="/guest" element={<GuestLecturesDetails eventId={"5"} />} /> */}
        {/* <Route path="/explore" element={<ComingSoon />} /> */}
        {/* <Route path="/merchandise" element={<Merchandise2 />} /> */}
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/schedule" element={<Schedule3 />} />
        <Route
          path="/gfg"
          element={
            <Redir path="https://us06web.zoom.us/meeting/register/tZAkdO2uqTItG9IYY9k4C-cx-jh7O26cz2ZH#/registration" />
          }
        />
        <Route path="/merchandise" element={<ComingSoon />} />
        <Route path="*" element={<ComingSoon id={1} />} />
      </Routes>
    </Router>
  );
}

export default App;
