import "../css/ComingSoon.css";
import { Link } from "react-router-dom";

function ComingSoon(props) {
  return (
    <div className="coming-soon">
      <div className="coming-soon-nssc">
        <img
          className="coming-soon-logo"
          src="./assets/img/nssc-logo.png"
          alt="logo"
        />
        <h1 className="coming-soon-nssc-title">
          National Students' Space Challenge 2023
        </h1>
      </div>
      <h1 className="coming-soon-title">
        <em>{props.id && props.id == 1 ? "404" : "Coming Soon!"}</em>
      </h1>
      <div>
        <div className="coming-soon-sa">
          <Link to="/">
            <button className="coming-soon-sa-button">Go to Home Page</button>
          </Link>
        </div>
        <div className="coming-soon-socials">
          <h2 className="coming-soon-socials-title">
            follow us on our Social Handles
          </h2>
          <div className="coming-soon-social-container">
            <a
              className="coming-soon-social"
              href="mailto:spats.iitkgp@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-solid fa-envelope"></i>
            </a>
            <a
              className="coming-soon-social"
              href="https://www.facebook.com/nssc.iit"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </a>
            <a
              className="coming-soon-social"
              href="https://www.instagram.com/spats.nssc.iitkgp/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a
              className="coming-soon-social"
              href="https://www.linkedin.com/company/national-students'-space-challenge/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              className="coming-soon-social"
              href="https://www.youtube.com/@spAtsiitkgp"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
