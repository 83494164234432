import React, { useState } from "react";
import "../css/Navbar1.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  let location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const removeMenu = () => {
    setIsOpen(false);
  };
  // console.log(location);
  if (location.pathname === "/") {
    return <></>;
  } else
    return (
      <header className="header-nssc">
        {/* <a href="#" className="logo">Logo</a> */}
        <Link onClick={removeMenu} to="/">
          <img
            src="/assets/img/nssc.png"
            alt=""
            style={{ width: "200px", paddingTop: "10px" }}
          />
        </Link>

        <input
          type="checkbox"
          id="check"
          checked={isOpen}
          onChange={toggleMenu}
        />
        <label htmlFor="check" className="icons-navbar">
          <i className="bx bx-menu" id="menu-icon"></i>
          <i className="bx bx-x" id="close-icon"></i>
        </label>

        <nav className={`navbar ${isOpen ? "active" : ""}`}>
          {/* <Link to="/about" className="nav-item" style={{ '--i': 0 }}>Home</Link>
                <Link to="/about" className="nav-item" style={{ '--i': 1 }}>Home</Link>
                <Link to="/about" className="nav-item" style={{ '--i': 2 }}>Home</Link>
                <Link to="/about" className="nav-item" style={{ '--i': 0 }}>Home</Link>
                <Link to="/about" className="nav-item" style={{ '--i': 0 }}>Home</Link> */}
          {/* <a href="#" className="nav-item" style={{ '--i': 1 }}>About</a>
                <a href="#" className="nav-item" style={{ '--i': 2 }}>Gallery</a>
                <a href="#" className="nav-item" style={{ '--i': 3 }}>Services</a>
                <a href="#" className="nav-item" style={{ '--i': 4 }}>Contact</a> */}

          <Link onClick={removeMenu} to="/about" className="nav-item">
            About
          </Link>
          <Link onClick={removeMenu} to="/events" className="nav-item">
            Events
          </Link>
          <Link onClick={removeMenu} to="/guestlectures" className="nav-item">
            Guests Lectures
          </Link>
          <Link onClick={removeMenu} to="/TalkShow" className="nav-item">
            Talk Show
          </Link>
          <Link onClick={removeMenu} to="/gallery" className="nav-item">
            Gallery
          </Link>

          <Link onClick={removeMenu} to="/schedule" className="nav-item">
            Schedule
          </Link>
          <Link onClick={removeMenu} to="/payment" className="nav-item">
            Accomodation
          </Link>
          <Link onClick={removeMenu} to="/team" className="nav-item">
            Team
          </Link>
          <Link onClick={removeMenu} to="/sponsors" className="nav-item">
            Sponsors
          </Link>
          <Link onClick={removeMenu} to="/contact" className="nav-item">
            Contact
          </Link>
          {localStorage.getItem("nssclogin") ? (
            <Link onClick={removeMenu} to="/profile" className="nav-item">
              Profile
            </Link>
          ) : (
            <Link onClick={removeMenu} to="/login" className="nav-item">
              Login
            </Link>
          )}
        </nav>
      </header>
    );
};

export default Navbar;
