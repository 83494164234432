import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../css/Events.css";
import events from "../data/events.json";

export default function Events() {
  const [astronaut, setAstronaut] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      let header = document.getElementById("events-holder");
      if (header.getBoundingClientRect().top < -30 && window.innerWidth > 851) {
        setAstronaut(true);
      } else {
        setAstronaut(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Eventcard = ({ data, ind }) => {
    return (
      <div className="event-card">
        <div
          className="event-card-inner"
          style={{
            backgroundImage: `url(/assets/img/events/${data.uid.replace(
              /\s/g,
              "_"
            )}.png)`,
            color: data.color,
          }}
        >
          <img src={data.img} />
          <div>
            <h2 className="event-title">
              <span className="event-uid">{data.uid}</span>
              <span>{data.name}</span>
            </h2>
            <p>
              <span>{data.sDesc}</span>
            </p>
            <Link to={data.path}>
              <strong>Explore</strong>
              <div className="container-stars">
                <div></div>
              </div>

              <div className="glow">
                <div className="circle"></div>
                <div className="circle"></div>
              </div>
            </Link>
          </div>
        </div>
        <div className="event-title">
          <h2>
            <span className="events-uid">{data.uid}</span>
            <span>{data.name}</span>
          </h2>
        </div>
      </div>
    );
  };

  return (
    <section className="events-page">
      <div className="events-header">
        <h1>
          <span>Conqueror</span> &nbsp;(Events)
        </h1>
        <p>
          Unveiling the Cosmic Horizon: Embark on an Odyssey to conquer the
          Uncharted Realms of the Universe!{" "}
        </p>
        <p>
          Dive into a captivating journey of unveiling the secrets of unexplored
          planets. Use your skills of maths, astronomy, robotics and analytics
          to explore, colonize, and thrive amidst the stars, rewriting the
          destiny of worlds untouched.
        </p>
        <img src="/assets/img/login/astronaut.png" alt="" />
      </div>
      {/* <img
        src="/assets/img/login/astronaut.png"
        alt=""
        className="fixed-astronaut"
        style={{ bottom: astronaut ? "20px" : "-400px" }}
      /> */}
      <div className="events-holder" id="events-holder">
        {Object.keys(events)
          .sort((a, b) => events[a].ord - events[b].ord)
          .map((val) => {
            if (val === "14")
              return (
                <React.Fragment key={"event" + (val + 1)}></React.Fragment>
              );
            return (
              <Eventcard
                data={events[val]}
                ind={val}
                key={"event" + (val + 1)}
              />
            );
          })}
      </div>
    </section>
  );
}
