import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import guestlectures from "../data/guestlectures.json";
import "../css/GuestLectures.css";

const Card = ({ id, data, status, register }) => {
  const icons = [
    "fa-solid fa-user-astronaut",
    "fa-solid fa-laptop-file",
    "fa-solid fa-display",
    "fa-solid fa-user-astronaut",
    "fa-solid fa-chalkboard-user",
    "fa-solid fa-user-astronaut",
  ];
  const types = [
    "Guest Lecture",
    "Workshop",
    "Webinar",
    "Talk Show",
    "Seminar",
    "Chief Guest",
  ];
  return (
    <div className="gl-card">
      <div className="gl-card-top"></div>
      <div className="gl-card-content">
        <div className="gl-card-image">
          <img src={data.guestImg} />
        </div>
        <div className="gl-card-description">
          <div className="gl-card-name">
            <i className={icons[data.type]}></i>
            <div>
              <p>{data.guestName}</p>
              <p>
                {types[data.type] +
                  (data.type === 1 || data.type === 4
                    ? " by " + data.byLine
                    : "")}
              </p>
            </div>
          </div>
          <div className="gl-card-line" />
          <div className="gl-card-about">
            {data.sDesc}
            <p>
              {data.Time ? (
                <>
                  <i className="fa-solid fa-clock timeline-icon" />
                  &nbsp;&nbsp;
                  {data.Time}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <></>
              )}
              {data.Date ? (
                <>
                  <i className="fa-solid fa-calendar-days timeline-icon" />
                  &nbsp;&nbsp;
                  {data.Date}
                </>
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="gl-card-bottom">
        {id != 1 &&
          (status == 1 && id != 9 ? (
            <span>Registered</span>
          ) : (
            <button>
              Registration Ended
            </button>
          ))}
        <Link to={data.path}>
          Know More <i className="fa-solid fa-arrow-right" />
        </Link>
      </div>
    </div>
  );
};

export default function GuestLectures() {
  const [regData, setRegData] = useState(null);
  const navigate = useNavigate();

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const getDetails = async () => {
    await Axios.get(process.env.REACT_APP_BACKEND_URL + "/user/gl/all", {
      headers: {
        authorization: localStorage.getItem("nssclogin"),
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          setRegData(response.data.data);
        } else if (response.data.status == 800 || response.data.status == 401) {
          toast.error(response.data.message, toastDict);
        } else {
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  const register = async (id, path) => {
    if (id != 9) {
      if (localStorage.getItem("nssclogin")) {
        await Axios.post(
          process.env.REACT_APP_BACKEND_URL + "/user/gl/" + id,
          {},
          {
            headers: {
              authorization: localStorage.getItem("nssclogin"),
            },
          }
        )
          .then((response) => {
            if (response.data.status == 200) {
              toast.success(response.data.message, toastDict);
              getDetails();
              if (id == 9) {
                window.open(
                  "https://us06web.zoom.us/meeting/register/tZAkdO2uqTItG9IYY9k4C-cx-jh7O26cz2ZH#/registration",
                  "_blank"
                );
              }
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
        navigate(path);
      } else {
        alert("Please login to register for this event.");
        navigate("/login");
      }
    } else {
      window.open(
        "https://us06web.zoom.us/meeting/register/tZAkdO2uqTItG9IYY9k4C-cx-jh7O26cz2ZH#/registration",
        "_blank"
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("nssclogin") && !regData) getDetails();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="gl">
        <div className="gl-heading">Guest Lectures, Workshops and Webinars</div>
        <div className="gl-container">
          {Object.keys(guestlectures).map(
            (val) =>
              val <= 9 &&
              val != 2 && (
                <Card
                  id={val}
                  data={guestlectures[val]}
                  status={regData ? regData[val] : null}
                  register={register}
                  key={val}
                />
              )
          )}
        </div>
      </div>
    </>
  );
}
