import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "../css/EventDetails.css";
import EventDetailsData from "../data/events.json";

export default function EventDetails({ eventId }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [regData, setRegData] = useState(null);
  const [newTeamDetails, setNewTeamDeatils] = useState(null);
  const [subLink, setSubLink] = useState("");
  const [updateSubmission, setUpdateSubmission] = useState(false);

  const currentEventData = EventDetailsData[eventId];

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const getDetails = async () => {
    await Axios.get(
      process.env.REACT_APP_BACKEND_URL + "/user/event/" + eventId,
      {
        headers: {
          authorization: localStorage.getItem("nssclogin"),
        },
      }
    )
      .then((response) => {
        if (response.data.status == 200) {
          setRegData(response.data.data);
        } else if (response.data.status == 800 || response.data.status == 401) {
          toast.error(response.data.message, toastDict);
        } else {
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  const registerIndividual = async () => {
    if (localStorage.getItem("nssclogin")) {
      setLoading(true);
      await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/register/" + eventId,
        {},
        {
          headers: {
            authorization: localStorage.getItem("nssclogin"),
          },
        }
      )
        .then((response) => {
          if (response.data.status == 200) {
            setLoading(false);
            toast.success(response.data.message, toastDict);
            getDetails();
          } else if (
            response.data.status == 800 ||
            response.data.status == 401
          ) {
            setLoading(false);
            toast.error(response.data.message, toastDict);
          } else {
            setLoading(false);
            toast.error("Something went wrong! Please try again.", toastDict);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "Something went wrong! Please check your internet connection.",
            toastDict
          );
        });
    } else {
      alert("Please login to register for the event.");
      navigate("/login");
    }
  };

  const registerTeam = async () => {
    if (localStorage.getItem("nssclogin")) {
      setLoading(true);
      await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/register/" + eventId,
        {
          teamName: newTeamDetails.teamName,
          pids: newTeamDetails.pids,
        },
        {
          headers: {
            authorization: localStorage.getItem("nssclogin"),
          },
        }
      )
        .then((response) => {
          if (response.data.status == 200) {
            setLoading(false);
            toast.success(response.data.message, toastDict);
            getDetails();
          } else if (
            response.data.status == 800 ||
            response.data.status == 401
          ) {
            setLoading(false);
            toast.error(response.data.message, toastDict);
          } else {
            setLoading(false);
            toast.error("Something went wrong! Please try again.", toastDict);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "Something went wrong! Please check your internet connection.",
            toastDict
          );
        });
    } else {
      alert("Please login to register for the event.");
      navigate("/login");
    }
  };

  const deleteTeam = async () => {
    if (localStorage.getItem("nssclogin")) {
      setLoading(true);
      await Axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/user/delete-team/" + eventId,
        {
          headers: {
            authorization: localStorage.getItem("nssclogin"),
          },
        }
      )
        .then((response) => {
          if (response.data.status == 200) {
            setLoading(false);
            toast.success(response.data.message, toastDict);
            getDetails();
          } else if (
            response.data.status == 800 ||
            response.data.status == 401
          ) {
            setLoading(false);
            toast.error(response.data.message, toastDict);
          } else {
            setLoading(false);
            toast.error("Something went wrong! Please try again.", toastDict);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "Something went wrong! Please check your internet connection.",
            toastDict
          );
        });
    } else {
      alert("Please login to register for the event.");
      navigate("/login");
    }
  };

  const submitEntry = async () => {
    if (localStorage.getItem("nssclogin")) {
      setLoading(true);
      await Axios.post(
        process.env.REACT_APP_BACKEND_URL + "/user/submit/" + eventId,
        {
          submission: subLink,
        },
        {
          headers: {
            authorization: localStorage.getItem("nssclogin"),
          },
        }
      )
        .then((response) => {
          if (response.data.status == 200) {
            setLoading(false);
            setUpdateSubmission(false);
            setSubLink("");
            toast.success(response.data.message, toastDict);
            getDetails();
          } else if (
            response.data.status == 800 ||
            response.data.status == 401
          ) {
            setLoading(false);
            toast.error(response.data.message, toastDict);
          } else {
            setLoading(false);
            toast.error("Something went wrong! Please try again.", toastDict);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            "Something went wrong! Please check your internet connection.",
            toastDict
          );
        });
    } else {
      alert("Please login to register for the event.");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("nssclogin") && !regData) getDetails();
  }, []);

  const eventDesc = (
    <div className="eventdetails-intro">
      <h1 className="eventdetails-intro-heading">Description</h1>
      <p className="eventdetails-intro-para">{currentEventData.desc}</p>
    </div>
  );

  const eventRules = (
    <section className="eventdetails-rules">
      <h1 className="eventdetails-rules-heading">Rules & Regulations</h1>
      <ul className="eventdetails-rules-list">
        {currentEventData.rules.map((data, index) => (
          <li key={index} className="eventdetails-rules-list-item">
            {data}
          </li>
        ))}
      </ul>
    </section>
  );

  const contactDetails = (
    <div className="eventdetails-contact">
      <h1 className="eventdetails-contact-heading">Contact</h1>
      <div>
        <div className="eventdetails-contact-details">
          <i className="fa-solid fa-headset headset-icon"></i>
          <div className="eventdetails-contact-details-item">
            <h2 className="eventdetails-contact-details-item-heading">
              Parag Karanjekar
            </h2>
            <h3 className="eventdetails-contact-details-item-subheading">
              +91 8411851866
            </h3>
          </div>
        </div>
        <div className="eventdetails-contact-details">
          <i className="fa-solid fa-headset headset-icon"></i>
          <div className="eventdetails-contact-details-item">
            <h2 className="eventdetails-contact-details-item-heading">
              Sandra K Joseph
            </h2>
            <h3 className="eventdetails-contact-details-item-subheading">
              +91 6235428593
            </h3>
          </div>
        </div>
      </div>
    </div>
  );

  const viewSubmission = (link) => {
    return (
      <a
        onClick={() => {
          if (eventId == 2) {
            toast.info("Your HackerRank Username: " + link, toastDict);
            return;
          }
          if (link.substring(0, 4) !== "http") link = "https://" + link;
          window.open(link, "_blank");
        }}
      >
        <button className="ps-submit-btn">
          View {eventId == 2 ? "HackerRank Username" : "Submission"}
        </button>
      </a>
    );
  };

  const updateSubmissionButton = (
    <button
      className="ps-submit-btn"
      style={{ marginTop: "10px" }}
      onClick={() => {
        setUpdateSubmission(true);
      }}
    >
      Update {eventId == 2 ? "HackerRank Username" : "Submission"}
    </button>
  );

  const submitField = (type) => (
    <>
      <br />
      <input
        type="url"
        name="ps-submission-link"
        id="ps-submission"
        placeholder={
          (currentEventData.ps ? !currentEventData.psLink : false)
            ? "Submission starts soon"
            : eventId == 2
              ? "HackerRank Username"
              : "Submission Link"
        }
        value={subLink}
        onChange={(e) => {
          setSubLink(e.target.value);
        }}
        disabled={currentEventData.ps ? !currentEventData.psLink : false}
      />
      {loading ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        <button
          className="ps-submit-btn"
          onClick={submitEntry}
          disabled={currentEventData.ps ? !currentEventData.psLink : false}
        >
          {type ? "UPDATE" : "SUBMIT"}
        </button>
      )}
    </>
  );

  const deadlineCheck =
    new Date() < new Date(currentEventData.deadline + " 23:59:59");

  const indEventRegDetails = (regData) => (
    <>
      <div className="eventdetails-registration-status">
        {regData.status != 0 ? (
          <div className="eventdetails-registration-status-registered">
            <i className="fa-solid fa-check eventdetails-register-icon"></i>
            <h1 className="eventdetails-registration-status-item-heading">
              {regData.status == 2 ? "Submitted" : "Registered"}
            </h1>
          </div>
        ) : (
          <div className="eventdetails-registration-status-unregistered">
            <i className="fa-solid fa-times eventdetails-register-icon"></i>
            <h1 className="eventdetails-registration-status-item-heading">
              Not Registered
            </h1>
          </div>
        )}
      </div>
      {regData.status == 0 ? (
        <div className="eventdetails-register">
          {deadlineCheck ? (
            <button
              className="eventdetails-register-btn"
              onClick={() => {
                registerIndividual();
              }}
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-spin"></i> : "Register"}
            </button>
          ) : (
            "Registration Deadline over"
          )}
        </div>
      ) : currentEventData.isSub ? (
        //submit online
        <div className="submission-ps">
          {regData.status == 2 ? (
            //submitted, can update
            <>
              {viewSubmission(regData.submission)}
              {deadlineCheck
                ? updateSubmission
                  ? submitField(1)
                  : updateSubmissionButton
                : "Submission Deadline over"}
              {eventId == 2 && (
                <div className="eventdetails-elink">
                  <a
                    href="https://www.hackerrank.com/astrobyte-preliminary-round"
                    target="_blank"
                    style={{ marginTop: "10px" }}
                  >
                    HackerRank Contest Link
                  </a>
                </div>
              )}
            </>
          ) : //first time submit
            deadlineCheck ? (
              submitField(0)
            ) : (
              "Submission Deadline over"
            )}
        </div>
      ) : (
        //submit on e link
        currentEventData.elink && (
          <div className="submission-ps">
            <div className="eventdetails-elink">
              <a href={currentEventData.elink} target="_blank">
                Register on Unstop
              </a>
            </div>
          </div>
        )
      )}
    </>
  );

  const teamEventRegDetails = (regData) => {
    return (
      <div className="eventdetails-register">
        {regData.teamID ? (
          <>
            {currentEventData.isSub ? (
              //submit online
              <div className="submission-ps">
                {regData.subLink ? (
                  //submitted, can update
                  <>
                    {viewSubmission(regData.subLink)}
                    {regData.leader ? (
                      //if leader
                      deadlineCheck ? (
                        updateSubmission ? (
                          submitField(1)
                        ) : (
                          updateSubmissionButton
                        )
                      ) : (
                        "Submission Deadline Over"
                      )
                    ) : (
                      //If participant
                      <>
                        <br />
                        {deadlineCheck
                          ? "ONLY TEAM LEADER CAN UPDATE THE SUBMISSION."
                          : "Submission Deadline Over"}
                      </>
                    )}
                  </>
                ) : //first time submit
                  regData.leader ? (
                    //if leader
                    deadlineCheck ? (
                      submitField(0)
                    ) : (
                      "Submission Deadline Over"
                    )
                  ) : (
                    //if participant
                    <>
                      <br />
                      {deadlineCheck
                        ? "ONLY TEAM LEADER CAN SUBMIT THE SOLUTION."
                        : "Submission Deadline Over"}
                    </>
                  )}
              </div>
            ) : (
              //submit on e link
              currentEventData.elink && (
                <div className="submission-ps">
                  <div className="eventdetails-elink">
                    <a href={currentEventData.elink} target="_blank">
                      Register on Unstop
                    </a>
                  </div>
                  PLEASE MAKE TEAM WITH THE SAME PEOPLE ON UNSTOP.
                </div>
              )
            )}
            <div className="eventdetails-team">
              <h1 className="eventdetails-team-heading">Team</h1>
              <div className="eventdetails-team-details">
                <h2 className="eventdetails-team-details-item-heading">
                  Team Name :
                  <b className="eventdetails-team-details-item-subheading">
                    {regData.teamName}
                  </b>
                </h2>
                <h2 className="eventdetails-team-details-item-heading">
                  Team ID :
                  <b className="eventdetails-team-details-item-subheading">
                    {regData.teamID}
                  </b>
                </h2>
              </div>
              <div className="eventdetails-team-details">
                <h2 className="eventdetails-team-details-item-heading">
                  Team Members :
                </h2>
                <h3 className="eventdetails-team-details-item-subheading">
                  {regData.pids.map((member, index) => (
                    <div key={index}>
                      {member.name} - {member.pid}
                    </div>
                  ))}
                </h3>
              </div>
              {regData.leader &&
                deadlineCheck &&
                (loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <button
                    className="eventdetails-register-btn"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      if (
                        confirm(
                          "Are you sure you want to delete the team and all its submission?"
                        )
                      )
                        deleteTeam();
                    }}
                  >
                    Delete Team
                  </button>
                ))}
            </div>
          </>
        ) : (
          <div className="eventdetails-create-team">
            {!newTeamDetails ? (
              deadlineCheck ? (
                <button
                  className="eventdetails-register-btn"
                  onClick={() => {
                    let temp = {
                      teamName: "",
                      pids: new Array(currentEventData.min).fill(""),
                    };
                    temp.pids[0] = regData;
                    setNewTeamDeatils(temp);
                  }}
                >
                  Create new team
                </button>
              ) : (
                "Registration Deadline Over"
              )
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  registerTeam();
                }}
                className="eventdeatils-create-team"
              >
                <input
                  type="text"
                  name="team-name"
                  id="team-name"
                  placeholder="Enter team name..."
                  required
                  autoComplete="off"
                  value={newTeamDetails.teamName}
                  onChange={(e) => {
                    setNewTeamDeatils({
                      ...newTeamDetails,
                      teamName: e.target.value,
                    });
                  }}
                />
                <div className="ed-participant-input">
                  {newTeamDetails.pids.map((input, index) => (
                    <div
                      style={{ width: "100%", position: "relative" }}
                      key={index}
                    >
                      <input
                        type="text"
                        name="participant-pid"
                        placeholder="Enter participant PID"
                        required
                        autoComplete="off"
                        value={input}
                        onChange={(e) => {
                          if (index == 0) return;
                          newTeamDetails.pids[index] = e.target.value;
                          setNewTeamDeatils({
                            ...newTeamDetails,
                          });
                        }}
                      />
                      {index >= currentEventData.min && (
                        <button
                          className="ed-minus-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            let temp = newTeamDetails.pids;
                            temp.splice(index, 1);
                            setNewTeamDeatils({
                              ...newTeamDetails,
                              pids: temp,
                            });
                          }}
                        >
                          -
                        </button>
                      )}
                    </div>
                  ))}
                  <br />
                  {newTeamDetails.pids.length < currentEventData.max && (
                    <button
                      className="ed-plus-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        newTeamDetails.pids.push("");
                        setNewTeamDeatils({
                          ...newTeamDetails,
                        });
                      }}
                    >
                      +
                    </button>
                  )}
                </div>
                {loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <input
                    type="submit"
                    value="Create Team"
                    className="create-team-btn"
                  />
                )}
              </form>
            )}
          </div>
        )}
      </div>
    );
  };

  const regDetails = (
    <>
      {
        // PS
        currentEventData.ps && (
          <div className="eventdetails-ps">
            <a href={currentEventData.psLink} target="_blank">
              <button
                className="eventdetails-ps-btn"
                disabled={!currentEventData.psLink}
              >
                {currentEventData.psLink
                  ? eventId == 2
                    ? "Rules and Regulations"
                    : "Problem Statement"
                  : "Problem Statement Coming Soon"}
              </button>
            </a>
          </div>
        )
      }
      <div
        style={{
          marginBlock: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <h1 className="eventdetails-contact-heading">
          {currentEventData.isSub ? "Submission Deadline" : "Date of Event"}
        </h1>
        <div
          style={{
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          {
            // Just Show Day, Month and Year
            new Date(currentEventData.deadline).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })
          }
        </div>
        {(eventId == 1 || eventId == 4 || eventId == 3) && (
          <p
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "1.5rem",
              color: "red",
            }}
          >
            (Submission Deadline Extended)
          </p>
        )}
      </div>

      {localStorage.getItem("nssclogin") ? (
        regData ? (
          currentEventData.team ? (
            teamEventRegDetails(regData)
          ) : (
            indEventRegDetails(regData)
          )
        ) : (
          <div className="eventdetails-register">Loading...</div>
        )
      ) : (
        <div className="eventdetails-register">
          <Link to="/login">
            <button className="eventdetails-register-btn">Register</button>
          </Link>
        </div>
      )}
    </>
  );

  const prizePool = (
    <div className="eventdetails-prizepool">
      <h1 className="eventdetails-contact-heading">Prize Pool</h1>
      <div className="eventdetails-prizepool-details">
        Rs. {currentEventData.prize}
      </div>
    </div>
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="eventdetails">
        {currentEventData && (
          <>
            <h1 className="eventdetails-heading">{currentEventData.name}</h1>
            <div className="eventdetails-main">
              <section className="eventdetails-intro-contact">
                {eventDesc}
                <div className="ed-contact-prize">
                  {contactDetails}
                  {currentEventData.prize && prizePool}
                </div>
              </section>
              <section
                className="eventdetails-central-section"
                style={{ overflow: "auto" }}
              >
                <img
                  className="eventdetails-image"
                  src={currentEventData.img}
                  alt=""
                />
                {regDetails}
              </section>
              {eventRules}
            </div>

            <div className="eventdetails-main mobile-view">
              <section className="eventdetails-intro-contact">
                <img
                  className="eventdetails-image"
                  src={currentEventData.img}
                  alt=""
                />
                {eventDesc}
              </section>
              <section className="eventdetails-central-section">
                {regDetails}
                <div>{currentEventData.prize && prizePool}</div>
              </section>
              {eventRules}
              {contactDetails}
            </div>
          </>
        )}
      </div>
    </>
  );
}
