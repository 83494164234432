import "../css/Profile.css";
import Objective from "../components/Objective.js";
import ObjectiveEvents from "../components/ObjectiveEvents.js";
import QuickInfo from "../components/QuickInfo.js";
import Infometers from "../components/Infometers.js";
import events from "../data/events.json";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function Profile() {
  const [participantData, setParticipantData] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const navigate = useNavigate();

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    async function getDetails() {
      if (localStorage.getItem("nssclogin")) {
        await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get-participant",
          {
            headers: {
              authorization: localStorage.getItem("nssclogin"),
            },
          }
        )
          .then((response) => {
            if (response.data.status == 200) {
              setParticipantData(response.data.data);
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
      } else navigate("/login");
    }
    getDetails();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="profile-page">
        {/* <div className="profile-header">
        <h1>MY PROFILE</h1>
      </div> */}
        <div className="profile-page-main-content">
          <div className="profile-page-section1">
            <div className="quick-info-section">
              <div className="section1-title">
                <h1>MY INFO</h1>
              </div>
              <div className="quick-info-wrap">
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["fName"]
                  }
                  statusBarFill={"40%"}
                  stausBarInfo={"FIRST NAME"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["lName"]
                  }
                  statusBarFill={"30%"}
                  stausBarInfo={"LAST NAME"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData
                      ? "Loading"
                      : participantData["email"].length > 20
                      ? participantData["email"].substring(0, 20) + "..."
                      : participantData["email"]
                  }
                  statusBarFill={"20%"}
                  stausBarInfo={"EMAIL"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["phone"]
                  }
                  statusBarFill={"15%"}
                  stausBarInfo={"PHONE"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["dob"]
                  }
                  statusBarFill={"55%"}
                  stausBarInfo={"DOB"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["gender"]
                  }
                  statusBarFill={"0%"}
                  stausBarInfo={"GENDER"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["college"]
                  }
                  statusBarFill={"38%"}
                  stausBarInfo={"COLLEGE"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["state"]
                  }
                  statusBarFill={"50%"}
                  stausBarInfo={"STATE/UT"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["city"]
                  }
                  statusBarFill={"19%"}
                  stausBarInfo={"CITY"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["level"]
                  }
                  statusBarFill={"86%"}
                  stausBarInfo={"LEVEL OF STUDY"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["roll"]
                  }
                  statusBarFill={"86%"}
                  stausBarInfo={"ROLL NO"}
                />
                <QuickInfo
                  statusBarName={
                    !participantData ? "Loading" : participantData["saID"]
                  }
                  statusBarFill={"86%"}
                  stausBarInfo={"SA-ID"}
                />
              </div>

              {/* <div className="profile-logout-button-wrap">
              <button className="profile-logout">
                LOGOUT
              </button>
            </div> */}
            </div>
            <div className="middle-section">
              <div className="infometers-container">
                {/* <div
                  className="profile-infometers profile-payment"
                  onClick={() => {
                    navigate("/payment");
                  }}
                >
                  <div className="infometer-name">PAYMENT</div>
                  <div className="infometer-icon">
                    <i className="fa-solid fa-wallet"></i>
                  </div>
                </div> */}
                <div
                  className="profile-infometers profile-qr"
                  onClick={() => {
                    setShowQR(true);
                  }}
                >
                  <div className="infometer-name">QR-CODE</div>
                  <div className="infometer-icon">
                    <i className="fa-solid fa-qrcode"></i>
                  </div>
                </div>
                {/* <div className="profile-infometers profile-merch">
                <div className="infometer-name">MERCH</div>
                <div className="infometer-icon">
                  <i className="fa-solid fa-cart-shopping"></i>
                </div>
              </div> */}
                <div
                  className="profile-infometers profile-logout-info"
                  onClick={() => {
                    localStorage.removeItem("nssclogin");
                    navigate("/");
                  }}
                >
                  <div className="infometer-name">LOGOUT</div>
                  <div className="infometer-icon">
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </div>
                </div>
              </div>
              <div className="profile-payment-text">
                {participantData && participantData.payment.status == 1 ? (
                  <p style={{ color: "green" }}>
                    Payment Successfully Completed!
                  </p>
                ) : (
                  <p style={{ color: "red" }}>&nbsp;</p>
                )}
              </div>
              <div className="astronaut-id">
                <p>
                  EXPLORER ID:{" "}
                  {!participantData ? "Loading" : participantData.pid}
                </p>
              </div>
              <div className="profile-astronaut-img">
                <img
                  className="profile-astronaut"
                  src=" /assets/img/profile/cute-astronaut.png"
                  alt=""
                />
                <img
                  className="profile-shadow"
                  src=" /assets/img/profile/astronaut-shadow.png"
                  alt=""
                />
                <img
                  className="profile-shadow-inverted"
                  src=" /assets/img/profile/astronaut-shadow-inverted.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="profile-page-section2">
            <div className="section2-title">
              {/* <h1>WORKSHOPS AND GUEST LECTURES</h1> */}
              <h1>EVENTS</h1>
            </div>
            <div className="objective-section">
              {participantData
                ? Object.keys(events)
                    .sort((a, b) => events[a].ord - events[b].ord)
                    .map((val) => {
                      if (val == 14)
                        return <React.Fragment key={val}></React.Fragment>;
                      return (
                        <Link
                          key={val}
                          to={events[val].path}
                          style={{ textDecoration: "none" }}
                        >
                          <ObjectiveEvents
                            title={events[val].name}
                            status={
                              true
                                ? //events
                                  events[val].team
                                  ? //team event
                                    participantData.events[val]
                                    ? //if submission is required
                                      events[val].isSub
                                      ? //if submitted
                                        participantData.events[val].subLink
                                        ? 2
                                        : 1
                                      : 2
                                    : 0
                                  : //individual event
                                  participantData.events[val].status
                                  ? participantData.events[val].status == 1
                                    ? //check if submission is required
                                      events[val].isSub
                                      ? 1
                                      : 2
                                    : 2
                                  : 0
                                : //gl
                                participantData.gl[val] === 2
                                ? 2
                                : participantData.gl[val]
                            }
                            message={
                              true
                                ? //events
                                  events[val].team
                                  ? //team event
                                    participantData.events[val]
                                    ? //if submitted
                                      participantData.events[val].subLink
                                      ? "Submitted - " +
                                        participantData.events[val].teamID
                                      : "Registered - " +
                                        participantData.events[val].teamID
                                    : "Register your team!"
                                  : //individual event
                                  participantData.events[val].status
                                  ? participantData.events[val].status == 2
                                    ? "Submitted"
                                    : "Registered"
                                  : "Register Now!"
                                : //gl
                                participantData.gl[val] === 2
                                ? "Attended"
                                : participantData.gl[val]
                                ? "Registered"
                                : "Register Now!"
                            }
                          />
                        </Link>
                      );
                    })
                : "Loading..."}
            </div>
          </div>
        </div>
        <div
          className="latest-updates-modal-holder"
          style={{ display: showQR ? "flex" : "none" }}
        >
          <div className="latest-updates-modal">
            <div className="latest-updates-modal-inner">
              <h1
                style={{
                  paddingBlock: "10px",
                  textAlign: "center",
                  fontSize: "2rem",
                  background: "#ffffff",
                }}
              >
                {!participantData ? "Loading" : participantData.pid}
              </h1>
              <img
                style={{ zIndex: "2000" }}
                src={
                  "https://chart.googleapis.com/chart?chs=280x280&cht=qr&chl=" +
                  (!participantData ? "Loading" : participantData.pid)
                }
              />
              <div
                className="latest-updates-modal-button"
                onClick={() => setShowQR(false)}
              >
                <i className="fa-solid fa-times" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
