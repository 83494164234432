import { useEffect, useState } from "react";
import "../css/Login.css";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import RegisterFormElements from "../components/RegisterFormElements";
import Axios from "axios";
import {
  buttonHandler,
  checkOTP,
  reSendOTP,
  loginHandler,
} from "../components/RegisterFormFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login(props) {
  const navigate = useNavigate();
  let [signupStep, setSignupStep] = useState(0);
  let [isRegister, setIsRegister] = useState(props.register);
  let [searchParams, setSearchParams] = useSearchParams();
  let [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  let [signupData, setSignupData] = useState({
    fName: "",
    lName: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
    saID: "",
    college: "",
    roll: "",
    city: "",
    state: "",
    level: "",
    password: "",
  });
  let [confirmPassword, setConfirmPassword] = useState("");
  let [otp, setOtp] = useState("");
  let [loading, setLoading] = useState(0);

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    if (signupStep === 5) navigate("/profile");
  }, [signupStep]);

  useEffect(() => {
    async function checkVerified() {
      if (localStorage.getItem("nssclogin")) {
        await Axios.post(
          process.env.REACT_APP_BACKEND_URL + "/auth/verified",
          {},
          {
            headers: {
              authorization: localStorage.getItem("nssclogin"),
            },
          }
        )
          .then((response) => {
            if (response.data.status === 200) {
              if (response.data.verified) setSignupStep(5);
              else setSignupStep(4);
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
      }
    }
    checkVerified();
  }, []);

  useEffect(() => {
    async function checkSA() {
      if (searchParams.get("said")) {
        await Axios.get(
          process.env.REACT_APP_BACKEND_URL +
            "/auth/check-sa/" +
            searchParams.get("said").toUpperCase()
        )
          .then((response) => {
            if (response.data.status === 200) {
              setSignupData({
                ...signupData,
                saID: searchParams.get("said").toUpperCase(),
              });
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
              navigate("/register");
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
      }
    }
    checkSA();
  }, []);

  const handleSignupChange = (e) => {
    setSignupData({
      ...signupData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="login">
        <div className="login-signup-options">
          {signupStep === 4 ? (
            <div className="signup-otp">
              <h2>Enter OTP</h2>
              <p>
                OTP was sent to{" "}
                {signupData.email !== ""
                  ? signupData.email
                  : "your registered email address"}
              </p>
              <div className="signup-otp-box">
                <input
                  type="password"
                  name="otp"
                  required={true}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
                <label>OTP</label>
              </div>
              <div className="signup-otp-footer">
                {loading ? (
                  <div
                    className="spinner-border"
                    role="status"
                    aria-hidden="true"
                  >
                    <div className="spinner-inner" />
                  </div>
                ) : (
                  <button
                    type="submit"
                    onClick={() => {
                      setTimeout(() => {
                        checkOTP(otp, setSignupStep, setLoading);
                      }, 180);
                    }}
                  >
                    Confirm
                  </button>
                )}

                {loading ? (
                  <></>
                ) : (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      reSendOTP(setLoading);
                    }}
                  >
                    Resend
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="login-signup-options-select">
                <h2>
                  <span
                    onClick={() => {
                      setIsRegister(0);
                    }}
                  >
                    Login
                  </span>
                  <span
                    onClick={() => {
                      setIsRegister(1);
                    }}
                  >
                    Register
                  </span>
                  <span
                    className="login-signup-options-select-slider"
                    style={{
                      left: isRegister ? "42.7%" : "0",
                      width: isRegister ? "57.3%" : "43.8%",
                    }}
                  ></span>
                </h2>
              </div>
              <div className="login-signup-options-box">
                <div
                  className="login-box"
                  style={{ display: !isRegister ? "block" : "none" }}
                >
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="login-user-box">
                      <input
                        type="text"
                        name="email"
                        required={true}
                        autoComplete="off"
                        value={loginData.email}
                        onChange={(e) =>
                          setLoginData({ ...loginData, email: e.target.value })
                        }
                      />
                      <label>Email</label>
                    </div>
                    <div className="login-user-box">
                      <input
                        type="password"
                        name="password"
                        required={true}
                        value={loginData.password}
                        onChange={(e) =>
                          setLoginData({
                            ...loginData,
                            password: e.target.value,
                          })
                        }
                      />
                      <label>Password</label>
                    </div>
                    <div className="login-form-footer">
                      {loading ? (
                        <div
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        >
                          {" "}
                          <div className="spinner-inner" />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          onClick={() => {
                            loginHandler(loginData, setLoading, setSignupStep);
                          }}
                        >
                          Login
                        </button>
                      )}
                      {loading ? (
                        <></>
                      ) : (
                        <span>
                          <Link to="/forgotpassword" style={{ color: "white" }}>
                            Forgot Password
                          </Link>
                        </span>
                      )}
                    </div>
                  </form>
                </div>
                <div
                  className="signup-box"
                  style={{ display: isRegister ? "block" : "none" }}
                >
                  <h2>
                    {
                      [
                        "Personal Details",
                        "Contact Details",
                        "College Details",
                        "Complete Registration",
                      ][signupStep]
                    }
                  </h2>
                  <p>Fields marked with * are required.</p>
                  <div className="signup-progress">
                    <span
                      className={signupStep >= 0 ? "signup-step-completed" : ""}
                    >
                      1
                    </span>
                    <span
                      className={signupStep >= 1 ? "signup-step-completed" : ""}
                    >
                      2
                    </span>
                    <span
                      className={signupStep >= 2 ? "signup-step-completed" : ""}
                    >
                      3
                    </span>
                    <span
                      className={signupStep >= 3 ? "signup-step-completed" : ""}
                    >
                      4
                    </span>
                    <div
                      className="signup-line"
                      style={{
                        background: `linear-gradient(to right, yellow 0%, yellow ${
                          signupStep * 33.3
                        }%, white ${signupStep * 33.3}%, white 100%)`,
                      }}
                    ></div>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <RegisterFormElements
                      slide={signupStep}
                      data={signupData}
                      handleChange={handleSignupChange}
                      saID={searchParams.get("said")}
                      setData={setSignupData}
                      cfPass={confirmPassword}
                      setCfPass={setConfirmPassword}
                    />
                    <div className="signup-form-footer">
                      {loading ? (
                        <div
                          className="spinner-border"
                          role="status"
                          aria-hidden="true"
                        >
                          {" "}
                          <div className="spinner-inner" />{" "}
                        </div>
                      ) : (
                        <button
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            setTimeout(() => {
                              buttonHandler(
                                signupStep,
                                setSignupStep,
                                signupData,
                                confirmPassword,
                                setLoading,
                                setIsRegister
                              );
                            }, 180);
                          }}
                        >
                          {" "}
                          {signupStep < 3 ? "Next" : "Submit"}{" "}
                        </button>
                      )}

                      {signupStep && !loading ? (
                        <button
                          type="cancel"
                          onClick={(e) => {
                            e.preventDefault();
                            setSignupStep(signupStep - 1);
                          }}
                        >
                          Back
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </form>
                </div>
              </div>{" "}
            </>
          )}
        </div>
        <img
          src="/assets/img/login/astronaut.png"
          alt=""
          className="login-astronaut-image"
        />
        <img
          src="/assets/img/login/moon.png"
          alt=""
          className="login-moon-image"
        />
        {/* <img
          src="/assets/img/login/saturn.png"
          alt=""
          className="login-saturn-image"
        />
        <div className="login-star-images">
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
        </div> */}
      </section>
    </>
  );
}
