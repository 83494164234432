import React, { useState, useEffect } from "react";
import Axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import guestlectures from "../data/guestlectures.json";
import "../css/GuestLectureDetails.css";
import { useNavigate } from "react-router-dom";

function GuestLecturesDetails({ id }) {
  const [loading, setLoading] = useState(false);
  const [regData, setRegData] = useState(null);
  const navigate = useNavigate();

  const data = guestlectures[id];

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const getDetails = async () => {
    await Axios.get(process.env.REACT_APP_BACKEND_URL + "/user/gl/" + id, {
      headers: {
        authorization: localStorage.getItem("nssclogin"),
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          setRegData(response.data.data);
        } else if (response.data.status == 800 || response.data.status == 401) {
          toast.error(response.data.message, toastDict);
        } else {
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  const register = async () => {
    if (id != 9) {
      if (localStorage.getItem("nssclogin")) {
        setLoading(true);
        await Axios.post(
          process.env.REACT_APP_BACKEND_URL + "/user/gl/" + id,
          {},
          {
            headers: {
              authorization: localStorage.getItem("nssclogin"),
            },
          }
        )
          .then((response) => {
            if (response.data.status == 200) {
              setLoading(false);
              toast.success(response.data.message, toastDict);
              getDetails();
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              setLoading(false);
              toast.error(response.data.message, toastDict);
            } else {
              setLoading(false);
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
      } else {
        alert("Please login to register for this event.");
        navigate("/login");
      }
    } else {
      window.open(
        "https://us06web.zoom.us/meeting/register/tZAkdO2uqTItG9IYY9k4C-cx-jh7O26cz2ZH#/registration",
        "_blank"
      );
    }
  };

  useEffect(() => {
    if (localStorage.getItem("nssclogin") && !regData) getDetails();
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="guest-page-container">
        <div className="guest-container">
          <div className="guest-profile-div">
            <img src={data.coverImg} alt="Image not available" />
            <div className="guest-details">
              <h2 className="guest-name">{data.guestName}</h2>
              <p className="guest-designation">{data.designation}</p>
              <div className="lecture-timeline">
                {data.venue && (
                  <div className="lecture-venue">
                    <i className="fa-solid fa-location-dot timeline-icon"></i>
                    <a href={data.venueLink} target="blank">
                      <h3>{data.venue}</h3>
                    </a>
                  </div>
                )}
                {data.Date && (
                  <div className="lecture-venue">
                    <i className="fa-solid fa-calendar-days timeline-icon"></i>
                    <h4> {data.Date}</h4>
                  </div>
                )}
                {data.Time && (
                  <div className="lecture-venue">
                    <i className="fa-solid fa-clock timeline-icon"></i>
                    <h4>{data.Time}</h4>
                  </div>
                )}
              </div>
              <div className="gl-register">
                {id != 1 ? (
                  !regData || id == 9 ? (
                    loading ? (
                      <button>
                        <i className="fa fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <button>Registration Ended</button>
                    )
                  ) : (
                    <h2 className="registered">
                      <i className="fa-solid fa-check eventdetails-register-icon"></i>
                      Registered
                    </h2>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="guest-intro">
            <div>
              {data.description
                ? data.description.map((val) => (
                  <React.Fragment key={val}>
                    <p>{val}</p>
                    <br />
                  </React.Fragment>
                ))
                : ""}
            </div>
          </div>
        </div>
        {data.eventDes && (
          <div className="lecture-des">
            <h1>{data.eventName}</h1>
            <div>
              {data.eventDes.map((val, idx) => (
                <React.Fragment key={idx}>
                  <p>{val}</p>
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        <div className="gl-contact">
          <h1>Contact</h1>
          <div className="gl-contact-details">
            <i className="fa-solid fa-headset headset-icon"></i>
            <p>
              <span>Swati Shreya</span>
              <br />
              <a href="tel:+918298496900">+91&nbsp;8298496900</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default GuestLecturesDetails;
