import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/Sponsor1.css";

// AOS.init();
// AOS.init({
//     duration: 1500,
// });

const Sponsors = () => {
  const [activeDiv, setActiveDiv] = useState(3);

  // AOS.init();
  // AOS.init({
  //     duration: 1500,
  // });

  useEffect(() => {
    AOS.init();
    AOS.init({
      duration: 1500,
    });

    return () => AOS.refresh();
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () {
      AOS.refresh();
    }, 500);
  });

  useEffect(() => {
    AOS.refresh();
  }, [activeDiv]);

  const handleButtonClick = (divNumber) => {
    setActiveDiv(divNumber);
  };

  return (
    <div className="sponsors">
      <div
        className="sponsors-heading"
        style={{
          width: "100%",
          color: "white",
          fontFamily: "font-family: 'Orbitron', sans-serif;",
        }}
      >
        <h1
          className="heading-sponsors-text"
          style={{ fontFamily: "'Orbitron', sans-serif;" }}
        >
          SPONSORS
        </h1>
      </div>

      <div className="div-sponsors-button" style={{ width: "100%" }}>
        <div className="button-sponsors">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover ${activeDiv === 1 ? "active" : ""}`}
            type="button"
            onClick={() => handleButtonClick(1)}
          >
            2021
          </button>
        </div>
        <div className="button-sponsors">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover ${activeDiv === 2 ? "active" : ""}`}
            type="button"
            onClick={() => handleButtonClick(2)}
          >
            2022
          </button>
        </div>
        <div className="button-sponsors">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover ${activeDiv === 3 ? "active" : ""}`}
            type="button"
            onClick={() => handleButtonClick(3)}
          >
            2023
          </button>
        </div>
      </div>

      <div className={`division ${activeDiv === 1 ? "active" : ""}`}>
        <div className="hero-section-sponsors-nssc">
          <div className="section-sponsors">
            <h1 data-aos="fade-right">TITLE SPONSOR</h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" style={{ backgroundColor: "#111" }}>
                <a
                  href="https://www.redwingsgroup.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/Redwings2.png" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              POWERED BY
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://sssutms.co.in/cms/Website"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/SSSUTMS.png" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              ASSOCIATE TITLE SPONSOR
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://ndcinstitute.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/ndc.jpg" alt="" />
                </a>
              </div>
            </div>
            {/* <h1 style={{ paddingTop: '2em' }}>EVENTS PARTNER</h1>
                        <div className="cards" data-aos="fade-left">
                            <div className="card" data-aos="fade-left" style={{ backgroundColor: "#111" }}>
                                <img src="/assets/img/sponsor/HoverRobotix-new.png" alt="" />
                            </div>
                        </div> */}

            {/* <div className="section-sponsors">
                            <h1>MEDIA PARTNER</h1>
                            <div className="cards">
                                <div className="card" data-aos="fade-right" style={{ backgroundColor: "#111" }}>
                                    <img src="/assets/img/sponsor/sammachar.png" alt="" />
                                </div>

                                <div className="card" data-aos="fade-up" style={{ backgroundColor: "#111" }}>
                                    <img src="/assets/img/sponsor/DUBEAT.png" alt="" />
                                </div>

                                <div className="card" data-aos="fade-left" style={{ backgroundColor: "#111" }}>
                                    <img src="/assets/img/sponsor/knowafest.png"
                                        alt="" />
                                </div>
                                <div className="card" data-aos="fade-right" style={{ backgroundColor: "#111" }}>
                                    <img src="/assets/img/sponsor/Nojoto.png" alt="" />
                                </div>
                                <div className="card" data-aos="fade-left" style={{ backgroundColor: "#111" }}>
                                    <img src="/assets/img/sponsor/BLOGADDA.png" alt="" />
                                </div>

                            </div>
                        </div> */}

            <div className="section-sponsors">
              <h1 data-aos="fade-right">GIFTS AND GOODIES PARTNER</h1>
              <div className="cards">
                <div
                  className="card"
                  data-aos="fade-right"
                  style={{ backgroundColor: "#111" }}
                >
                  <a
                    href="https://www.geeksforgeeks.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/GeeksforGeeks.png" alt="" />
                  </a>
                </div>
                <div
                  className="card"
                  data-aos="fade-left"
                  style={{ backgroundColor: "#111" }}
                >
                  <a
                    href="https://prestogifts.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/Presto.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              MERCHANDISE PARTNER
            </h1>
            <div className="cards">
              <div
                className="card"
                data-aos="fade-left"
                style={{ backgroundColor: "#111" }}
              >
                <a
                  href="https://www.geeksforgeeks.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/L.png" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              PAYMENT PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.townscript.com/in/online"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/TOWNSCRIPT.png" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              PLATFORM PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://unstop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/DARE2COMPETE.png"
                    alt=""
                    style={{ width: "280px" }}
                  />
                </a>
              </div>
            </div>
            {/* <h1 style={{ paddingTop: '2em' }}>TECH PARTNER</h1>
                        <div className="cards">
                            <div className="card" data-aos="fade-left" style={{ backgroundColor: "#111" }}>
                                <img src="/assets/img/sponsor/av.jpg" alt="" />
                            </div>
                        </div> */}

            <div className="section-sponsors">
              <h1 data-aos="fade-right">EVENT PARTNERS</h1>
              <div className="cards">
                <div
                  className="card"
                  data-aos="fade-right"
                  style={{ backgroundColor: "#111" }}
                >
                  <a
                    href="https://www.csm.tech/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/CSMtech.png" alt="" />
                  </a>
                </div>
                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.time4education.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/TIMELucknow.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>

            {/* <h1>TRAVEL PARTNER</h1>
                        <div className="cards">
                            <div className="card" data-aos="fade-up" style={{ backgroundColor: "#111" }}>
                                <img src="/assets/img/sponsor/krishnaAirTravels.png" alt="" />
                            </div>
                        </div> */}
          </div>
        </div>
      </div>

      <div className={`division ${activeDiv === 2 ? "active" : ""}`}>
        <div className="hero-section-sponsors-nssc">
          <div className="section-sponsors">
            <h1 data-aos="fade-right">TITLE SPONSOR</h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" style={{ backgroundColor: "#111" }}>
                <a
                  href="https://www.redwingsgroup.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/Redwings2.png" alt="" />
                </a>
              </div>
            </div>
            <h1 data-aos="fade-right" style={{ paddingTop: "2em" }}>
              POWERED BY
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://www.sconto.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/sconto_nssc.png" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              MAJOR PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://ndcinstitute.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/ndc.jpg" alt="" />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              EVENTS PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://hoverrobotix.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/HoverRobotix.png" alt="" />
                </a>
              </div>
            </div>

            <div className="section-sponsors">
              <h1 data-aos="fade-right">MEDIA PARTNER</h1>
              <div className="cards">
                <div className="card" data-aos="fade-right">
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/img/sponsor/sammachar.png" alt="" />
                  </a>
                </div>

                <div className="card" data-aos="fade-up">
                  <a
                    href="https://dubeat.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/DUBEAT.png" alt="" />
                  </a>
                </div>

                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.knowafest.com/explore/events"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/knowafest.png" alt="" />
                  </a>
                </div>
                <div className="card" data-aos="fade-right">
                  <a
                    href="https://nojoto.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/Nojoto-new.png" alt="" />
                  </a>
                </div>
                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.blogadda.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/BLOGADDA-new.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div className="section-sponsors">
              <h1 data-aos="fade-right">GIFTS AND GOODIES PARTNER</h1>
              <div className="cards">
                <div className="card" data-aos="fade-right">
                  <a
                    href="https://www.geeksforgeeks.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/GeeksforGeeks.png" alt="" />
                  </a>
                </div>
                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.fraxus3d.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/fraxus3d.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <h1 data-aos="fade-right">PLATFORM PARTNER</h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://unstop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/unstop.svg"
                    alt=""
                    style={{ width: "280px" }}
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              TECH PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.analyticsvidhya.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/av.jpg"
                    alt=""
                    style={{ scale: "0.8" }}
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              LIFE SKILLS PARTNER
            </h1>
            <div className="cards">
              <div
                className="card"
                data-aos="fade-left"
                style={{ scale: "0.8" }}
              >
                <a
                  href="https://getrevup.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/RevUp.png"
                    alt=""
                    style={{ paddingRight: "4px" }}
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              WORKSHOP PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://thementorx.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/mentorx-new.png" alt="" />
                </a>
              </div>
            </div>
            <div className="section-sponsors">
              <h1 data-aos="fade-up">FOOD PARTNERS</h1>
              <div className="cards">
                <div className="card" data-aos="fade-right">
                  <a target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/img/sponsor/ScrummysLogo-new.png"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card" data-aos="fade-left">
                  <a target="_blank" rel="noopener noreferrer">
                    <img src="/assets/img/sponsor/FarEastLogo.png" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <h1 data-aos="fade-right">TRAVEL PARTNER</h1>
            <div className="cards">
              <div className="card" data-aos="fade-up">
                <a target="_blank" rel="noopener noreferrer">
                  <img src="/assets/img/sponsor/krishnaAirTravels.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`division ${activeDiv === 3 ? "active" : ""}`}>
        <div className="hero-section-sponsors-nssc">
          <div className="section-sponsors">
            <h1 data-aos="fade-right">TITLE SPONSOR</h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" style={{ backgroundColor: "#111" }}>
                <a
                  href="https://www.newindia.co.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/new-india-assurance-logo (1).png"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <h1 data-aos="fade-right">CO-TITLE SPONSOR</h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" style={{ backgroundColor: "#111" }}>
                <a
                  href="https://www.redwingsgroup.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/Redwings2.png" alt="" />
                </a>
              </div>
            </div>

            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              MAJOR SPONSOR
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://ndcinstitute.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/ndc.jpg" alt="" />
                </a>
              </div>
            </div>

            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              YOUTH MEDIA PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://youthincmag.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/Youth_Inc_New_Logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              YOUTH COMMUNITY PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.theeducationtree.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/The Education Treee-3.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              BLOG PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://edtimes.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/EDTimes.png"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              BLOGGER OUTREACH PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.blogadda.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/BLOGADDA-new.png" alt="" />
                </a>
              </div>
            </div>

            <div className="section-sponsors">
              <h1 data-aos="fade-right">MEDIA PARTNER</h1>
              <div className="cards">
                <div className="card" data-aos="fade-right">
                  <a
                    href="https://theglobalhues.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/sponsor/channels4_profile.jpg"
                      alt=""
                    />
                  </a>
                </div>

                {/* <div className="card" data-aos="fade-up">
                  <a
                    href="https://dubeat.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/images.png" alt="" />
                  </a>
                </div> */}

                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.knowafest.com/explore/events"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/knowafest.png" alt="" />
                  </a>
                </div>
                {/* <div className="card" data-aos="fade-right">
                  <a
                    href="https://nojoto.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/youth.png" alt="" />
                  </a>
                </div> */}
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              ENTERTAINMENT PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.niccoparks.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/nicco-park.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>

            {/* <div className="section-sponsors">
              <h1 data-aos="fade-right">GIFTS AND GOODIES PARTNER</h1>
              <div className="cards">
                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.niccoparks.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/img/sponsor/sponsors-images-nssc/nicco-park.jpg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div> */}

            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              CODING PARTNER
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.geeksforgeeks.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/GeeksforGeeks.png" alt="" />
                </a>
              </div>
            </div>
            <h1 data-aos="fade-right">SOCIAL INITIATIVE PARTNER</h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.dkms-bmst.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/img/sponsor/dkms.png" alt="" />
                </a>
              </div>
            </div>
            <h1 data-aos="fade-right">EDUCATION PARTNER</h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.elearnmarkets.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/2937_ELM_ELM Logo by StockEDge 3600 X 1163 Px.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              KNOWLEDGE PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://learningwhiletravelling.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/001_LWT_logo.png"
                    alt=""
                  />
                </a>
              </div>
            </div>

            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              OUTREACH PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a
                  href="https://www.befikracommunity.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/BL1.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <h1 style={{ paddingTop: "2em" }} data-aos="fade-right">
              SPONSORSHIP PARTNER
            </h1>
            <div className="cards">
              <div className="card" data-aos="fade-left">
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img
                    src="/assets/img/sponsor/sponsors-images-nssc/gms_logo1.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="section-sponsors">
              <h1 data-aos="fade-right">FOOD PARTNERS</h1>
              <div className="cards">
                <div className="card" data-aos="fade-right">
                  <a
                    href="https://teatimegroup.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/Tea-Time-logo-1.png" alt="" />
                  </a>
                </div>
                <div className="card" data-aos="fade-up">
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/img/sponsor/sponsors-images-nssc/Sunday-food-truck.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card" data-aos="fade-up">
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <img
                      src="/assets/img/sponsor/sponsors-images-nssc/food-capital.jpg"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card" data-aos="fade-left">
                  <a
                    href="https://www.vadilalicecreams.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/img/sponsor/vadilal.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <h1 data-aos="fade-right" style={{ paddingTop: "2em" }}>
              POWERED BY
            </h1>
            <div className="cards" data-aos="fade-left">
              <div className="card">
                <a
                  href="https://unstop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/assets/img/sponsor/unstop.svg"
                    alt=""
                    style={{ width: "280px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
        .division {
          display: none;
        }
        .division.active {
          display: block;
        }
        
      `}
      </style>
    </div>
  );
};

export default Sponsors;
