import "../css/QuickInfo.css";

export default function QuickInfo({statusBarName, statusBarFill, stausBarInfo}) {
  return (
    <div className="profile-quick-info">
      <div className="status-bar-info">
        {stausBarInfo}
      </div>
      <div className="status-bar">
        {statusBarName}
        {/* <div className="loading-bar">
          <div className="loading-bar-fill" style={{width: statusBarFill }}></div>
        </div> */}
      </div>
    </div>
  );
}


