import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";
import "../css/Payment.css";

export default function Payment() {
  const [participantData, setParticipantData] = useState(null);
  const navigate = useNavigate();

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  /*useEffect(() => {
    async function getDetails() {
      if (localStorage.getItem("nssclogin")) {
        await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/get-participant",
          {
            headers: {
              authorization: localStorage.getItem("nssclogin"),
            },
          }
        )
          .then((response) => {
            if (response.data.status == 200) {
              console.log(response.data.data);
              setParticipantData(response.data.data);
            } else if (
              response.data.status == 800 ||
              response.data.status == 401
            ) {
              toast.error(response.data.message, toastDict);
            } else {
              toast.error("Something went wrong! Please try again.", toastDict);
            }
          })
          .catch((error) => {
            toast.error(
              "Something went wrong! Please check your internet connection.",
              toastDict
            );
          });
      } else navigate("/login");
    }
    getDetails();
  }, []);*/

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <p className="payment-closed">PAYMENT PORTAL HAS BEEN CLOSED.</p>
      <section className="payment-page">
        <div className="payment-body payment-right">
          {/*false && participantData && participantData.payment.status == 0 ? (
            <iframe
              id="ts-iframe"
              src={`https://www.townscript.com/v2/widget/nssc2023/booking?&name=${
                participantData.fName + " " + participantData.lName
              }&emailid=${participantData.email}&cq1=${
                participantData.phone
              }&cq2=${participantData.pid}&cq3=${participantData.gender}`}
              frameBorder="0"
              height="600"
              width="80%"
            />
          ) : participantData && participantData.payment.status > 1 ? (
            <h1>Payment Completed Successfully!</h1>
          ) : (
            <h1>Loading...</h1>
          )*/}
          <div>
            <div>
              <div className="payment-body-left-header">
                <h2>Accomodation Rules and Regulations</h2>
              </div>
              <ul className="payment-body-left-body">
                <li>
                  All individuals are required to visibly wear their ID cards at
                  all times.
                </li>
                <li>
                  Preservation of hall property is mandatory, and any damage
                  will result in strict disciplinary measures.
                </li>
                <li>
                  {" "}
                  Please refrain from causing disturbances to fellow hall
                  residents.
                </li>
                <li>
                  The use or possession of narcotics and intoxicants is strictly
                  prohibited; the premises may be subject to inspection by the
                  Narcotics Control Bureau (NCB).
                </li>
                <li>
                  Maintain respectful conduct towards all team members and
                  participants.
                </li>
                <li>
                  Take necessary precautions to secure your personal belongings.
                  The management is not liable for any losses.
                </li>
                <li>Access to other halls of residence is restricted.</li>
                <li>Usage of the hall mess is not permitted.</li>
                <li>
                  Attendance in all scheduled activities is required for every
                  participant.
                </li>
                <li>
                  {" "}
                  As a safety measure, accommodation rooms will be secured and
                  locked during certain fest activities
                </li>
                <li>
                  All participants are expected to be in their accommodation
                  rooms by 12 a.m. Exceptions apply to participants involved in
                  events; they should promptly return to their accommodation
                  rooms after concluding their respective events.
                </li>
                <li>
                  Post-midnight, personal safety is the individual's
                  responsibility, and any incidents that occur thereafter are at
                  the participant's own risk.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="payment-body payment-left">
          <div>
            <div>
              <div className="payment-body-left-header">
                <h2>NSSC-23 Pass</h2>
              </div>
              <ul className="payment-body-left-body">
                <li>
                  Accommodation for 3 days and 2 nights (6th October to 8th
                  October) in IIT Kharagpur Campus.
                </li>
                <li>
                  Includes complementary official merchandise of NSSC 2023.
                </li>
                <li>
                  Access to exciting events like Liftoff, Hoverpod, Gyrobot,
                  Maze Runner, and many more.
                </li>
                <li>
                  Entrance to the Arena with mesmerising exhibitions and amazing
                  activities.
                </li>
                <li>
                  Access to exhibition of real-life scaled-down models of ISRO
                  rockets and satellites.
                </li>
                <li>
                  Free entrance to exciting Guest Lectures and Webinars by
                  renowned scientists.
                </li>
                <li>Pass for the Talk Show by Gaurav Thakur (GetSetFly).</li>
              </ul>
            </div>
            <div>
              {/* <div className="payment-body-left-header">
                <h2>Rules and Guidelines</h2>
              </div>
              <ul className="payment-body-left-body">
                <li>
                  You can use any of the following payment methods to pay -
                  Google Pay, Paytm, Amazon Wallet, Net Banking, Credit Card and
                  Debit Card.
                </li>
                <li>
                  Only college students are allowed to get accomodation inside
                  the campus.
                </li>
                <li>No refunds will be provided under any circumstances.</li>
                <li>
                  Participants are requested to wait for 48 hours for
                  confirmation of payment status. In case the payment status
                  does not reflect, contact us at{" "}
                  <a href="mailto:dhamdhereshriram@gmail.com">
                    dhamdhereshriram@gmail.com
                  </a>
                  .
                </li>
                <li>
                  Incase of any dispute in the subject of payment status the
                  decision of organising team will be considered final.
                </li>
                <li>
                  Incase of any dispute in the subject of facilities provided
                  against the payment the decision of organising team will be
                  considered final.
                </li>
                <li>
                  Travel and food costs for the participants will be borne by
                  the participants and will not be reimbursed by the organising
                  team under any circumstances.
                </li>
                <li>
                  Participant must carry a valid identity proof and be
                  registered on the website to avail above mentioned facilities.
                </li>
                <li>
                  Participants are responsible for their belongings. Organising
                  team will not be responsible for any losses or damages to
                  their belongings.
                </li>
                <li>
                  In case of any damage to Institute property, participants will
                  be liable to the damages.
                </li>
                <li>
                  Narcotics, liquor, cigarettes and other sorts of intoxicants
                  are strictly prohibited on campus.
                </li>
                <li>
                  In case of any violation of rules and regulations,
                  participants will face disqualification and may be asked to
                  leave the premises.
                </li>
                <li>
                  Organising team will not intervene in any case arising due to
                  violation of rules and regulation laid down by Institute.
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
