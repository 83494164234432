export default function RegisterFormElements(props) {
  const handleDDChange = (name, value) => {
    props.setData({ ...props.data, [name]: value });
  };

  const stateOptions = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ].sort();

  if (props.slide === 0) {
    return (
      <>
        <div className="signup-user-box signup-multi">
          <div className="signup-user-box">
            <input
              type="text"
              name="fName"
              required={true}
              autoComplete="off"
              value={props.data.fName}
              onChange={props.handleChange}
            />
            <label className="required">First Name</label>
          </div>
          <div className="signup-user-box">
            <input
              type="text"
              name="lName"
              required={true}
              autoComplete="off"
              value={props.data.lName}
              onChange={props.handleChange}
            />
            <label>Last Name</label>
          </div>
        </div>
        <div className="signup-user-box signup-filled">
          <input
            type="date"
            name="dob"
            required={true}
            autoComplete="off"
            value={props.data.dob}
            onChange={props.handleChange}
          />
          <label className="required">Date of Birth</label>
        </div>
        <div
          className={
            "signup-user-box signup-dd" +
            (props.data.gender ? " signup-filled" : "")
          }
        >
          <input
            type="text"
            name="gender"
            required={true}
            autoComplete="none"
            value={props.data.gender}
            onChange={props.handleChange}
            readOnly={true}
          />
          <label className="required">Gender</label>
          <label className="downarrow">
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </label>
          <ul>
            <li onClick={() => handleDDChange("gender", "Male")}>Male</li>
            <li onClick={() => handleDDChange("gender", "Female")}>Female</li>
          </ul>
        </div>
      </>
    );
  } else if (props.slide === 1) {
    return (
      <>
        <div className="signup-user-box">
          <input
            type="text"
            name="email"
            required={true}
            autoComplete="off"
            value={props.data.email}
            onChange={props.handleChange}
          />
          <label className="required">Email</label>
        </div>
        <div className="signup-user-box">
          <input
            type="phone"
            name="phone"
            required={true}
            autoComplete="off"
            value={props.data.phone}
            onChange={props.handleChange}
          />
          <label className="required">Phone Number</label>
        </div>
        <div
          className={`signup-user-box signup-SA ${props.saID ? "signup-filled" : ""
            }`}
        >
          <input
            type="text"
            name="saID"
            required={true}
            autoComplete="off"
            value={props.data.saID}
            onChange={props.handleChange}
            readOnly={props.saID}
          />
          <label>Student Ambassador ID (if any)</label>
        </div>
      </>
    );
  } else if (props.slide === 2) {
    return (
      <>
        <div className={"signup-user-box"}>
          <input
            type="text"
            name="city"
            required={true}
            autoComplete="off"
            value={props.data.city}
            onChange={props.handleChange}
          />
          <label className="required">City of College</label>
        </div>
        <div
          className={
            "signup-user-box signup-dd state" +
            (props.data.state ? " signup-filled" : "")
          }
        >
          <input
            type="text"
            name="state"
            required={true}
            autoComplete="none"
            value={props.data.state}
            // onChange={props.handleChange}
            readOnly={true}
          />
          <label className="required">State of College</label>
          <label className="downarrow">
            <i className="fa-sharp fa-solid fa-caret-down"></i>
          </label>
          <ul>
            {stateOptions.map((state) => (
              <li key={state} onClick={() => handleDDChange("state", state)}>
                {state}
              </li>
            ))}
          </ul>
        </div>
        <div className="signup-user-box">
          <input
            type="text"
            name="college"
            required={true}
            autoComplete="off"
            value={props.data.college}
            onChange={props.handleChange}
          />
          <label className="required">School/College Name</label>
        </div>
        <div className="signup-user-box signup-multi">
          <div className="signup-user-box">
            <input
              type="text"
              name="roll"
              required={true}
              autoComplete="off"
              value={props.data.roll}
              onChange={props.handleChange}
            />
            <label>College Roll No.</label>
          </div>
          <div
            className={
              "signup-user-box signup-dd" +
              (props.data.level ? " signup-filled" : "")
            }
          >
            <input
              type="text"
              name="level"
              required={true}
              autoComplete="none"
              value={props.data.level}
              readOnly={true}
            />
            <label className="required">Level of Study</label>
            <label className="downarrow">
              <i className="fa-sharp fa-solid fa-caret-down"></i>
            </label>
            <ul>
              <li onClick={() => handleDDChange("level", "High School")}>
                High School
              </li>
              <li onClick={() => handleDDChange("level", "Undergraduate")}>
                Undergraduate
              </li>
              <li onClick={() => handleDDChange("level", "Postgraduate")}>
                Postgraduate
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  } else if (props.slide === 3) {
    return (
      <>
        <div className="signup-user-box signup-password">
          <input
            type="password"
            name="password"
            required={true}
            value={props.data.password}
            onChange={props.handleChange}
          />
          <label className="required">Password</label>
        </div>
        <div className="signup-user-box signup-password">
          <input
            type="password"
            name="confirmpass"
            required={true}
            value={props.cfPass}
            onChange={(e) => props.setCfPass(e.target.value)}
          />
          <label className="required">Confirm Password</label>
        </div>
      </>
    );
  }
}
