import React from "react";
import "../css/LandingPage.css";
import { Link } from "react-router-dom";
import ParticlesBackground from "../components/ParticlesBackground";

function LandingPage() {
  return (
    <div className="background-container-landing">
      <ParticlesBackground />
      {/* <img
        src="/assets/img/landing/background-img-nssc-1.png"
        alt="Background"
        className="background-img-landing"
      /> */}
      {/* <div className="astronaut-container">
        <img
          src="/assets/img/landing/astranout.png"
          alt="Astronaut"
          className="astronaut-img-landing"
        />   
      </div> */}
      <div className="logo-container">
        <img
          src="/assets/img/landing/FB profile (2).png"
          alt="Logo"
          className="logo-img"
        />
        <div className="right-logo">
          <img
            src="/assets/img/landing/isro-logo.png"
            alt="Background"
            className="isro-logo"
          />
          <img
            src="/assets/img/landing/iit-logo.png"
            alt="Background"
            className=" iit-logo"
          />
        </div>
        <div className="left-logo"></div>
      </div>

      <div className="center-content">
        <div className="title-sponsor-landing">
          <img
            src="/assets/img/landing/title_sponsor.png"
            alt="Background"
            className="background-img-title"
          />
        </div>
        <h2 className="title-landing" >
          National Students' Space Challenge 2023
        </h2>
        <h2 className="sub-title-landing">FORA GALAXIA</h2>
        {/* <h2 className="subtitle">Unveiling the Cosmic Horizon</h2> */}
        <div className="wrapper-landing">
          <div className="typing-demo" >Unveiling the Cosmic Horizon</div>
        </div>
        <div className="button-container-landing">
          <Link to="/events" style={{ textDecoration: "none" }}>
            <button className="button-landing-nssc">
              <span className="text">Let's Explore</span>
            </button>
          </Link>
          <Link
            to={localStorage.getItem("nssclogin") ? "/profile" : "/login"}
            style={{ textDecoration: "none" }}
          >
            <button className="button-landing-nssc">
              <span className="text">
                {localStorage.getItem("nssclogin") ? "Profile" : "Login"}
              </span>
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="social-media-icons">
                <a href="#" className="social-icon"><i className="fa-brands fa-facebook fa-beat fa-6xs"></i></a>
                <a href="#" className="social-icon"><i className="fab fa-twitter"></i></a>
                <a href="#" className="social-icon"><i className="fab fa-instagram"></i></a>
            </div> */}
      <div className="social-media-icons">
        <ul className="social-media-icons-nssc">
          <li>
            <a
              href="https://www.facebook.com/nssc.iit/"
              target="_blank"
              className="logo-anchor-tag"
            >
              <i className="fab fa-facebook" tabIndex="0"></i>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/i/flow/login?redirect_after_login=%2FspAts_IITkgp"
              target="_blank"
              className="logo-anchor-tag"
            >
              <i className="fab fa-twitter" tabIndex="0"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/spats.nssc.iitkgp/"
              target="_blank"
              className="logo-anchor-tag"
            >
              <i className="fab fa-instagram" tabIndex="0"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UChnpOiISqo7ea5yuGc2ghXQ"
              target="_blank"
              className="logo-anchor-tag"
            >
              <i className="fab fa-youtube" tabIndex="0"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default LandingPage;
