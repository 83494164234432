import React from "react";
import "../css/Day1.css";

function Day0() {
  return (
    <div className="timeline">
      <h1 style={{ color: "white" }}>Schedule Day 0</h1>
      <ul>
        <li data-aos="fade-left">
          <div
            className="date"
            data-aos-duration="500"
            style={{ backgroundColor: "#279EFF" }}
          >
            6PM onwards
          </div>
          <div className="title-descr-container">
            <div className="title">Opening ceremony </div>
            <div className="descr">
              <i
                className="fa-sharp fa-solid fa-location-dot"
                style={{ paddingTop: "1px" }}
              ></i>
              <div className="location-schedule">
                <a
                  style={{ textDecoration: "None" }}
                  href="https://maps.app.goo.gl/R2btj7EyKsR4jw1q6"
                  target="_blank"
                >
                  Netaji Auditorium
                </a>
              </div>
            </div>
          </div>
        </li>

        {/* <li data-aos="fade-right">
          <div
            className="date"
            data-aos-duration="500"
            style={{ backgroundColor: "#279EFF" }}
          >
            9PM
          </div>
          <div className="title-descr-container">
            <div className="title">Astrobyte</div>
            <div className="descr">
              <i
                className="fa-sharp fa-solid fa-location-dot"
                style={{ paddingTop: "1px" }}
              ></i>
              <div className="location-schedule">
                <a
                  style={{ textDecoration: "None" }}
                  href="https://maps.app.goo.gl/XhXRew5JVWXGEumH8"
                  target="_blank"
                >
                  CIC Takshashila
                </a>
              </div>
            </div>
          </div>
        </li> */}

        <li data-aos="fade-right">
          <div
            className="date"
            data-aos-duration="500"
            style={{ backgroundColor: "#279EFF" }}
          >
            10PM onwards
          </div>
          <div className="title-descr-container">
            <div className="title">Sky Gazing</div>
            <div className="descr">
              <i
                className="fa-sharp fa-solid fa-location-dot"
                style={{ paddingTop: "1px" }}
              ></i>
              <div className="location-schedule">
                <a
                  style={{ textDecoration: "None" }}
                  href="https://maps.app.goo.gl/VFGN2Nd6bwNmPuXQ6"
                  target="_blank"
                >
                  Tata Steel Complex
                </a>
              </div>
            </div>
          </div>
        </li>
        {/* <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>2 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Braitenberg Challenge</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Vikramshila Foyer
                        </a></div></div></div>
                </li>
                <li data-aos="fade-right" data-aos-duration="500" style={{ height: "180px" }}>
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>3 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Workshop 1</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            V1 Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>3 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Pre-Finals of Space quiz</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>



                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>5 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Gyrobot</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Vikramshila Foyer
                        </a></div></div></div>
                </li>
                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>5 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Case Study</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>6:30 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Workshop 2</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            V1 Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>9 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Finals of Space quiz</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>
                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>9 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Stargazing Session</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Tata Steel Complex
                        </a></div></div></div>
                </li> */}
      </ul>
    </div>
  );
}

export default Day0;
