import React from 'react'
import "../css/Day1.css";

function Day2() {
    return (
        <div className="timeline">
            <h1 style={{ color: "white" }}>Schedule Day 2</h1>
            <ul>
                <li data-aos="fade-left" >
                    <div className="date" data-aos-duration="500" style={{ backgroundColor: "#279EFF" }}>9 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >FRB and ML Webinar</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            V2 Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-right">
                    <div className="date" data-aos-duration="500" style={{ backgroundColor: "#279EFF" }} >9 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Paper Presentation</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/RqwUK8sKHWo6xLjP9"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>
                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>10 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Liftoff Finals</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/RGJuYp6KCmbriNEh7"
                            target="_blank"
                        >
                            Mahatma Gandhi Ground
                        </a></div></div></div>
                </li>
                {/* <br /> */}
                <li data-aos="fade-right" data-aos-duration="500" style={{ height: "180px" }}>
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>11 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Maze Runner</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Vikramshila Foyer
                        </a></div></div></div>
                </li>

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>11 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Python Workshop</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/922GndVKGgNCqV258"
                            target="_blank"
                        >
                            Nalanda(NR121)
                        </a></div></div></div>
                </li>



                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>1 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Data Analytics</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Maitryee Auditorium

                        </a></div></div></div>
                </li>

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>3 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Space Quiz Final</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>
                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>4 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Space Colonization Seminar</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/922GndVKGgNCqV258"
                            target="_blank"
                        >
                            Nalanda(NR121)
                        </a></div></div></div>
                </li>




                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>6:30 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Closing Ceremony</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/R2btj7EyKsR4jw1q6"
                            target="_blank"
                        >
                            Netaji Auditorium
                        </a></div></div></div>
                </li>
            </ul>

        </div>


    );
}

export default Day2