import React from 'react'
import "../css/Day1.css";

function Day1() {
    return (
        <div className="timeline">
            <h1 style={{ color: "white" }}>Schedule Day 1</h1>
            <ul>
                <li data-aos="fade-left" >
                    <div className="date" data-aos-duration="500" style={{ backgroundColor: "#279EFF" }}>9 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >SYSTEMS SAFETY, AI/ML IN SPACE</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            V2 Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-right">
                    <div
                        className="date"
                        data-aos-duration="500"
                        style={{ backgroundColor: "#279EFF" }}
                    >
                        9 AM
                    </div>
                    <div className="title-descr-container">
                        <div className="title">Astrobyte</div>
                        <div className="descr">
                            <i
                                className="fa-sharp fa-solid fa-location-dot"
                                style={{ paddingTop: "1px" }}
                            ></i>
                            <div className="location-schedule">
                                <a
                                    style={{ textDecoration: "None" }}
                                    href="https://maps.app.goo.gl/XhXRew5JVWXGEumH8"
                                    target="_blank"
                                >
                                    CIC Takshashila
                                </a>
                            </div>
                        </div>
                    </div>
                </li>

                <li data-aos="fade-right">
                    <div className="date" data-aos-duration="500" style={{ backgroundColor: "#279EFF" }} >9 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Astroid Venture</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            Gargi Auditorium
                        </a></div></div></div>
                </li>
                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>10 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Round 1 Liftoff</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/g93vKY1YFBq6xQx17"
                            target="_blank"
                        >
                            Mahatma Gandhi Ground
                        </a></div></div></div>
                </li>
                {/* <br /> */}
                <li data-aos="fade-right" data-aos-duration="500" style={{ height: "180px" }}>
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>11 AM</div>
                    <div className="title-descr-container">
                        <div className="title" >Aerosoc - ANSYS Workshop</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            V2 Auditorium
                        </a></div></div></div>
                </li>

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>2 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Space Quiz Prefinals</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            Maitryee Auditorium
                        </a></div></div></div>
                </li>



                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>4 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Black Hole Guest Lecture</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/F18wR8F87McYb5636"
                            target="_blank"
                        >
                            V3 Auditorium
                        </a></div></div></div>
                </li>
                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>4 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Hoverpod</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Vikramshila Foyer
                        </a></div></div></div>
                </li>

                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>5 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Case Study</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            Maitryee Auditorium
                        </a></div></div></div>
                </li>

                {/* <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>7 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Space Quiz Finals (School)</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href=""
                            target="_blank"
                        >
                            Online
                        </a></div></div></div>
                </li> */}

                <li data-aos="fade-left" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>8 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Talk Show</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/NxUrVr6eCPTqS6Yb8"
                            target="_blank"
                        >
                            Kalidas
                        </a></div></div></div>
                </li>
                {/* <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>10 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Drone Show</div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                            target="_blank"
                        >
                            Arena
                        </a></div></div></div>
                </li> */}
                <li data-aos="fade-right" data-aos-duration="500">
                    <div className="date" style={{ backgroundColor: "#279EFF" }}>10 PM</div>
                    <div className="title-descr-container">
                        <div className="title" >Cosmomath </div>
                        <div className="descr" ><i className="fa-sharp fa-solid fa-location-dot" style={{ paddingTop: "1px" }}></i><div className='location-schedule'><a style={{ textDecoration: "None" }}
                            href="https://maps.app.goo.gl/VrryFnSrCo17v9YN6"
                            target="_blank"
                        >
                            Maitryee Auditorium
                        </a></div></div></div>
                </li>
            </ul>

        </div>


    );
}

export default Day1