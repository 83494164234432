// import Particles from "react-tsparticles";
// import particlesConfig from "./config/particles-config";
// import { loadFull } from "tsparticles";
// export default function ParticleBackground() {
// 	async function loadParticles(main){
//         await loadFull(main)
//     }
// 	return (
// 		<Particles
// 			params={particlesConfig}
// 			id="tsparticles"
// 			init={particlesInit}
// 			loaded={particlesLoaded}
// 		/>
// 	);
// }

// import Particles from "react-tsparticles";
// import particlesConfig from "./config/particles-config";
// import { loadFull } from "tsparticles";
// export default function ParticleBackground() {
// 	const particlesInit = async (main) => {
// 		console.log(main);
// 		await loadFull(main);
// 	};
// 	const particlesLoaded = (container) => {
// 		console.log(container);
// 	};
// 	return (
//         <div className="particle-bg">
//             <Particles
//                 params={particlesConfig}
//                 id="tsparticles"
//                 init={particlesInit}
//                 loaded={particlesLoaded}
//             />
//         </div>
// 	);
// }

// import "./styles.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function App() {
  const particlesInit = async (main) => {
    console.log(main);
    await loadFull(main);
  };

  return (
    <div className="App">
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          particles: {
            number: {
              value: 100,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: "#ffffff",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 1,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: true,
                mode: "repulse",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 100,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  );
}
