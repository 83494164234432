import "../css/About.css";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
export default function About2() {
  const [currentNumber, setCurrentNumber] = useState([0, 0, 0, 0]);
  const [showNumber, setShowNumber] = useState(false);
  const targetNumber = [10000, 1000, 120, 12]; // Change this to the desired final number
  const animationDuration = 1000; // Change this to the desired animation duration in milliseconds
  const framesPerSecond = 50;
  // const increment = (targetNumber[0]) / (animationDuration / 1000) / framesPerSecond;

  useEffect(() => {
    const handleScroll = (event) => {
      let header = document.getElementById("about-transition");
      if (header.getBoundingClientRect().top < 500) {
        setShowNumber(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    function updateNumber() {
      if (showNumber) {
        for (let i = 0; i < targetNumber.length; i++) {
          if (currentNumber[i] < targetNumber[i]) {
            let increment =
              targetNumber[i] / (animationDuration / 1000) / framesPerSecond;
            setCurrentNumber((prevNumber) =>
              prevNumber.map((val, ind) => val + (ind === i ? increment : 0))
            );
          } else {
            clearInterval(intervalId);
          }
        }
      }
    }

    intervalId = setInterval(updateNumber, 1000 / framesPerSecond);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentNumber, showNumber]);
  return (
    <>
      <div className="about_page">
        <div className="about_planet">
          <div className="about_head">
            <div className="about_main">About NSSC</div>
            <div className="about_sub">
              {" "}
              The National Students' Space Challenge (NSSC) is the centrepiece
              of spAts. As the name suggests, NSSC is a national-level fest
              dedicated to space and space technology. We operate intending to
              spread awareness about the space and provide a platform to
              students yearning to explore the possibilities of the vast
              expanses of the unknown.{" "}
            </div>
            {/* <div className="about_button">
              <Link to="/events" className="about_link">
                <div className="about_button1">EVENTS</div></Link>
              <Link to="/register" className="about_link">
                <div className="about_button2">REGISTER</div></Link>
            </div> */}
          </div>
          <div className="about_img">
            <img src="/assets/img/about/about_logo.png" height={465} />
          </div>
        </div>
        {/* <div className="about_phototitle about_title">
          <h1>CAPTURED MOMENTS</h1>
        </div>
        <div className="about_paragraph ">
          <div className="about_wrapper">
            <div className="about_at-accordion__item">
              <img src="/assets/img/about/about_img1.webp" height={372} />
            </div>
            <div className="about_at-accordion__item">
              <img src="/assets/img/about/about_img2.webp" height={372} />
            </div>
            <div className="about_at-accordion__item">
              <img src="/assets/img/about/about_img5.webp" height={372} />
            </div>
            <div className="about_at-accordion__item">
              <img src="/assets/img/about/about_img6.webp" height={372} />
            </div>
          </div>
        </div> */}

        <div className="about_timeline">
          <div className="about_inner_box">
            <div className="about_title">
              <h1>TIMELINE</h1>
            </div>
            <div className="about_earth">
              <img
                src="/assets/img/about/earthimg.png"
                className="about_earth_img"
              />
            </div>
            <div className="about_page1">
              <div className="about-line"></div>
              <div className="about_box1">
                <div className="about_event1">
                  <div className="about_event_name">26th July</div>
                  <div className="about_event_description">
                    Registration for Student Ambassador
                  </div>
                </div>
                <div className="about_event3">
                  <div className="about_event_name">28th August</div>
                  <div className="about_event_description">
                    Registration for NSSC Starts
                  </div>
                </div>
                <div className="about_event5">
                  <div className="about_event_name">6th October</div>
                  <div className="about_event_description">
                    Reporting for NSSC
                  </div>
                </div>
              </div>
              <div className="about_box2">
                <div className="about_event2">
                  <div className="about_event_name">26th August</div>
                  <div className="about_event_description">
                    Theme Release of NSSC
                  </div>
                </div>
                <div className="about_event4">
                  <div className="about_event_name">2nd September</div>
                  <div className="about_event_description">
                    Online Events Start
                  </div>
                </div>
                <div className="about_event6">
                  <div className="about_event_name">6th-8th October</div>
                  <div className="about_event_description">NSSC 2023</div>
                </div>
              </div>
            </div>
            <div className="about_moon">
              <img
                src="/assets/img/about/moonimg.png"
                className="about_moon_img"
              />
            </div>
          </div>
        </div>
        <div className="about_table">
          <div className="about_title_line1 about_title">
            {" "}
            <h1>A Comprehensive Exploration</h1>
          </div>
          <div className="about_title_line2" id="about-transition">
            Space Technology Students' Society, or spAts, is the official
            student body under Kalpana Chawla Space Technology Cell, IIT
            Kharagpur. Established in 2008 by a small group of young and
            ambitious undergraduate students, spAts had humble beginnings. A
            small club founded as a congregation of students with a common
            interest called space, spAts started as an ordinary student-run
            society with big ambitions. Since its inception, the society has
            seen multiple changes. From what used to be a regular space
            discussion forum, spAts evolved into a professionally run society
            that hosts many events, along with the first and largest space
            technology fest in India, the National Students' Space Challenge.
            <br />
            <br />
            The National Students' Space Challenge (NSSC) is the centrepiece of
            spAts. As the name suggests, NSSC is a national-level fest dedicated
            solely towards space and space technology. The fest began in 2011
            when the society decided to independently host a fest to provide a
            platform to the aspiring engineers and scientists of India who were
            only waiting for an opportunity to showcase their talents. In its
            inaugural year, NSSC had just one event: Liftoff. The event, a
            competition to build the best water rocket with given
            specifications, spoke to the hearts of space enthusiasts and
            remained the fest's highlight after 11 long years. Various other
            events, such as autonomous and manual bot-making, space quizzes,
            case studies and astrophotography, have been added over the years.
            Besides the events, the National Students' Space Challenge is famous
            for its supremely successful guest lectures, exhibitions and
            workshops.
            <br />
          </div>
          {/* <div className="abouttable">
              <table>
                <tr>
                  <th>Year</th>
                  <th>Number of journeys</th>
                  <th>Duration (months)</th>
                  <th>People living on mars</th>
                </tr>
                <tr>
                  <td>11</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>14</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>15</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>16</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>17</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>18</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>19</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>20</td>
                  <td>10</td>
                  <td>7</td>
                  <td>25</td>
                </tr>
              </table>
            </div> */}
        </div>

        <div className="about_wholecard" id="about-cards">
          <div className="about_cardtitle about_title">
            <h1>Statistics</h1>
          </div>
          <div className="about_card">
            <div className="about_card-background-1"></div>

            <div className="about_card-content">
              <div className="number">{`${Math.round(currentNumber[3])}+`}</div>
              <div className="about_upperline">Successful Years</div>
              <div className="about_lowerline">
                NSSC has been conducted with great success for the previous 12
                years and has been a platform widely loved by aspiring space
                enthusiasts in India.
              </div>
            </div>
          </div>
          <div className="about_card">
            <div className="about_card-background-1"></div>

            <div className="about_card-content">
              <div className="number">{`${Math.round(currentNumber[2])}+`}</div>
              <div className="about_upperline">STAC</div>
              <div className="about_lowerline">
                The society has conducted STAC (Space Technology Awareness Camp)
                on more than 120 different occasions, all inspiring and
                cultivating an interest in cosmology in students nationwide.
              </div>
            </div>
          </div>
          <div className="about_card">
            <div className="about_card-background-1"></div>

            <div className="about_card-content">
              <div className="number">{`${Math.round(currentNumber[1])}+`}</div>
              <div className="about_upperline">Yearly participants</div>
              <div className="about_lowerline">
                Each year, IIT Kharagpur sees more than 1,000 participants from
                across the nation coming together to brainstorm and compete on a
                level playing field.
              </div>
            </div>
          </div>

          <div className="about_card">
            <div className="about_card-background-1"></div>
            <div className="about_card-content">
              <div className="number">{`${Math.round(currentNumber[0])}+`}</div>
              <div className="about_upperline">Total participants</div>
              <div className="about_lowerline">
                The National Students’ Space Challenge has seen over 10,000
                participants across the years, and the number only continues to
                grow.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
