import GalleryImage from "../data/GalleryImages.json";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "../css/Gallery.css";
function Gallery() {
  const [data, setData] = useState([]);
  const [collection, setCollection] = useState([]);
  const [filtercategory, setFiltercategory] = useState("Arena");
  const [images, setImages] = useState([]);
  const [autoPlay, setAutoPlay] = useState(false);
  var img;
  useEffect(() => {
    setData(GalleryImage);
    setCollection(
      GalleryImage.map((item) => {
        return item.category;
      })
    );
  }, []);
  useEffect(() => {
    setData(
      GalleryImage.filter((item) => {
        return item.category == filtercategory;
      })
    );
  }, [filtercategory]);

  useEffect(() => {
    setImages(
      data.map((item) => {
        return item.images;
      })[0]
    );
  }, [data]);
  useEffect(() => {
    //Any code after image load
    if (images) {
      setTimeout(() => {
        document
          .querySelector(".image-gallery-play-button")
          .addEventListener("click", () => {
            setAutoPlay(!autoPlay);
          });
        startAutoPlay();
        const el = document.querySelector(".image-gallery");
        img = document.querySelectorAll(".image-gallery-image");

        el.addEventListener("fullscreenchange", fullscreenchanged);
      }, 10);
    }
  }, [images]);

  const startAutoPlay = () => {
    if (!autoPlay) {
      document.querySelector(".image-gallery-play-button").click();
    }
  };
  useEffect(() => {
    if (!autoPlay) {
      document.querySelector(".image-gallery-play-button").click();
    }
  }, [autoPlay]);

  function fullscreenchanged(event) {
    if (document.fullscreenElement) {
      // img.setAttribute("style", "width:100% !important");
      for (let i = 0; i < img.length; i++) {
        img[i].classList.add("fullscreen");
      }
    } else {
      for (let i = 0; i < img.length; i++) {
        img[i].classList.remove("fullscreen");
      }
    }
  }
  // or
  // el.onfullscreenchange = fullscreenchanged;

  return (
    <div className="gallery">
      <div className="gallery-Heading">
        <h2>GALLERY</h2>
      </div>
      <div className="gallery-filterItem">
        <ul>
          {collection.map((item, index) => (
            <li key={index}>
              <button
                className={`gallery-filterButton ${
                  filtercategory == item ? "active" : ""
                }`}
                onClick={() => {
                  setFiltercategory(item);
                  setAutoPlay(false);
                }}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="gallery-images">
        {images && <ImageGallery items={images} showBullets={true} />}
      </div>
    </div>
  );
}

export default Gallery;
