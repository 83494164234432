import React, { useState, useEffect } from "react";
import "../css/Schedule.css";
import Day0 from "../components/Day0";
import Day1 from "../components/Day1";
import Day2 from "../components/Day2";
import AOS from "aos";

const Schedule3 = () => {
  const [activeDiv, setActiveDiv] = useState(1);

  useEffect(() => {
    AOS.init();
    AOS.init({
      duration: 1500,
    });

    return () => AOS.refresh();
  }, []);

  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function () {
      AOS.refresh();
    }, 500);
  });

  useEffect(() => {
    AOS.refresh();
  }, [activeDiv]);

  const handleButtonClick = (divNumber) => {
    setActiveDiv(divNumber);
  };

  return (
    <div className="schedule">
      <div className="div-schedule-button" style={{ width: "100%" }}>
        <div className="button-schedule">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover-schedule ${activeDiv === 1 ? "active" : ""
              }`}
            type="button"
            onClick={() => handleButtonClick(1)}
          >
            Day 0
          </button>
        </div>
        <div className="button-schedule">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover-schedule ${activeDiv === 2 ? "active" : ""
              }`}
            type="button"
            onClick={() => handleButtonClick(2)}
          >
            Day 1
          </button>
        </div>
        <div className="button-schedule">
          <button
            style={{ fontWeight: "800", fontSize: "1.3em" }}
            className={`glow-on-hover-schedule ${activeDiv === 3 ? "active" : ""
              }`}
            type="button"
            onClick={() => handleButtonClick(3)}
          >
            Day 2
          </button>
        </div>
      </div>
      <div className={`division ${activeDiv === 1 ? "active" : ""}`}>
        <Day0 />
      </div>

      <div className={`division ${activeDiv === 2 ? "active" : ""}`}>
        <Day1 />
      </div>

      <div className={`division ${activeDiv === 3 ? "active" : ""}`}>
        <Day2 />
      </div>

      <style>
        {`
        .division {
          display: none;
        }
        .division.active {
          display: block;
        }
        .button img {
            opacity: 0.5;
            transition: 0.4s ease;
            width: 146px;
            cursor: pointer;
        }
          .button img.active {
            opacity: 1;
            width: 150px;
        }
      `}
      </style>
    </div>
  );
};

export default Schedule3;
