import "../css/contact.css";
function contact() {
  return (
    <section className="contact">
      <div className="contact-wrapper" style={{ zIndex: "4" }}>
        <div className="contact-header">
          <div className="contact-heading">
            <h2>Contact Us !</h2>
            <p>
              Please get in touch with us, in case you have any queries or
              questions.
            </p>
          </div>
          <div className="contact-info">
            <div className="contact-info-content">
              <p style={{ display: "flex" }} className="contact-link-wrap">
                <i className="fa-sharp fa-solid fa-location-dot contact-link-icon"></i>
                <a
                  href="https://goo.gl/maps/PYpQvktJYKdvDQD39"
                  target="_blank"
                  style={{ textAlign: "initial" }}
                >
                  Kalpana Chawla Space Technology Cell, IIT Kharagpur
                </a>
              </p>
            </div>

            <div className="contact-info-content">
              <p className="contact-link-wrap">
                <i className="fas fa-phone contact-link-icon"></i>{" "}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "initial",
                    gap: "0.5rem",
                  }}
                >
                  <a href="tel:+919359873722"> +91-9359873722</a>
                  <a href="tel:+919350152471"> +91-9350152471</a>
                </div>
              </p>
            </div>

            <div className="contact-info-content">
              <p className="contact-link-wrap">
                {" "}
                <i className="fa fa-globe contact-link-icon"></i>
                <a href="https://spats.co.in"> spats.co.in</a>
              </p>
            </div>

            <div className="contact-info-content">
              <p className="contact-link-wrap">
                {" "}
                <i className="fas fa-envelope  contact-link-icon"></i>{" "}
                <a href="mailto:spats.iitkgp@gmail.com">
                  spats.iitkgp@gmail.com
                </a>{" "}
              </p>
            </div>
          </div>
          <div className="contact-social-links">
            <a href="https://www.facebook.com/nssc.iit" target="_blank">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/nssciitkgp/" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/national-students'-space-challenge/"
              target="_blank"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="https://twitter.com/spats_iitkgp" target="_blank">
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>

        <div className="contact-form-div">
          <form
            action="https://formsubmit.co/669dc954a998fcb4465d54f40dd69f53"
            method="POST"
            id="contact-form"
          >
            <h1>Send Your Query !</h1>
            <div className="contact-input-box">
              <input
                type="text"
                required={true}
                name="name"
                placeholder="Name"
              />
            </div>

            <div className="contact-input-box">
              <input
                type="email"
                required={true}
                name="email"
                placeholder="Email address"
              />
            </div>
            <div className="contact-input-box">
              <input
                type="text"
                required={true}
                name="tel"
                placeholder="Phone number"
              />
            </div>

            <div className="contact-input-box">
              <textarea
                required={true}
                name="query"
                placeholder="Write your query"
                rows={5}
              ></textarea>
            </div>

            <div className="contact-input-box">
              <input type="submit" value="Submit" name="" />
              <input
                type="hidden"
                name="_next"
                value="http://nssc.in/contact"
              ></input>
              <input type="hidden" name="_captcha" value="false"></input>
              <input type="hidden" name="_template" value="table"></input>
              <input
                type="hidden"
                name="_autoresponse"
                value="Thanks for reaching out! Your message was received! We will reply shortly."
              ></input>
              <input
                type="hidden"
                name="_subject"
                value="New submission!"
              ></input>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
export default contact;
