import { useEffect, useState } from "react";
import "../css/Login.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { handlePass } from "../components/RegisterFormFunctions";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const navigate = useNavigate();
  let [forgotStage, setForgotStage] = useState(0);
  let [forgetData, setForgetData] = useState({
    email: "",
    opt: "",
    password: "",
    confirmPassword: "",
  });
  let [loading, setLoading] = useState(0);

  const sendOTP = async () => {
    setLoading(true);
    await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/auth/forget-password",
      {
        email: forgetData.email,
      }
    )
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          toast.success(response.data.message, toastDict);
          setForgotStage(1);
        } else if (response.data.status == 800 || response.data.status == 401) {
          setLoading(false);
          toast.error(response.data.message, toastDict);
        } else {
          setLoading(false);
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  const verifyOTP = async () => {
    setLoading(true);
    await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/auth/verify-forget-otp",
      {
        email: forgetData.email,
        otp: forgetData.opt,
      }
    )
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          localStorage.setItem("nssclogin", response.data.token);
          toast.success(response.data.message, toastDict);
          setForgotStage(2);
        } else if (response.data.status == 800 || response.data.status == 401) {
          setLoading(false);
          toast.error(response.data.message, toastDict);
        } else {
          setLoading(false);
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  const resetPassword = async () => {
    setLoading(true);
    await Axios.post(
      process.env.REACT_APP_BACKEND_URL + "/auth/reset-password",
      {
        password: forgetData.password,
      },
      {
        headers: {
          authorization: localStorage.getItem("nssclogin"),
        },
      }
    )
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          toast.success(response.data.message, toastDict);
          setForgotStage(3);
        } else if (response.data.status == 800 || response.data.status == 401) {
          setLoading(false);
          toast.error(response.data.message, toastDict);
        } else {
          setLoading(false);
          toast.error("Something went wrong! Please try again.", toastDict);
        }
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please check your internet connection.",
          toastDict
        );
      });
  };

  useEffect(() => {
    if (forgotStage == 3) navigate("/profile");

    if (
      localStorage.getItem("nssclogin") &&
      (forgotStage == 0 || forgotStage == 1)
    )
      navigate("/profile");
  }, [forgotStage]);

  let toastDict = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const loadingButton = (
    <div className="spinner-border" role="status" aria-hidden="true">
      {" "}
      <div className="spinner-inner" />{" "}
    </div>
  );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <section className="login">
        <div className="login-signup-options">
          <>
            <div className="login-signup-options-select">
              <h2>
                <span>Forgot Password</span>
                <span
                  className="login-signup-options-select-slider"
                  style={{
                    left: "0",
                    width: "100%",
                  }}
                ></span>
              </h2>
            </div>
            <div className="login-signup-options-box">
              <div className="signup-box" style={{ display: "block" }}>
                <h2>
                  {
                    ["Enter Email Address", "Enter OTP", "Enter New Password"][
                      forgotStage
                    ]
                  }
                </h2>
                <div
                  className="signup-progress"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span
                    style={{
                      margin: 0,
                    }}
                    className={forgotStage >= 0 ? "signup-step-completed" : ""}
                  >
                    1
                  </span>
                  <span
                    style={{
                      margin: 0,
                    }}
                    className={forgotStage >= 1 ? "signup-step-completed" : ""}
                  >
                    2
                  </span>
                  <span
                    style={{
                      margin: 0,
                    }}
                    className={forgotStage >= 2 ? "signup-step-completed" : ""}
                  >
                    3
                  </span>
                  <div
                    className="signup-line"
                    style={{
                      background: `linear-gradient(to right, yellow 0%, yellow ${
                        forgotStage * 50
                      }%, white ${forgotStage * 50}%, white 100%)`,
                    }}
                  ></div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (forgotStage == 0) {
                      sendOTP();
                    } else if (forgotStage == 1) {
                      verifyOTP();
                    } else if (forgotStage == 2) {
                      if (handlePass(forgetData, forgetData.confirmPassword)) {
                        resetPassword();
                      }
                    }
                  }}
                >
                  {forgotStage == 0 && (
                    <>
                      <div className="signup-user-box">
                        <input
                          type="text"
                          name="email"
                          required={true}
                          autoComplete="off"
                          value={forgetData.email}
                          onChange={(e) => {
                            setForgetData({
                              ...forgetData,
                              email: e.target.value,
                            });
                          }}
                        />
                        <label className="required">Email</label>
                      </div>
                      <div className="signup-form-footer">
                        {loading ? (
                          loadingButton
                        ) : (
                          <button type="submit">Sent OTP</button>
                        )}
                      </div>
                    </>
                  )}
                  {forgotStage == 1 && (
                    <div className="signup-otp">
                      <p>
                        OTP was sent to{" "}
                        {forgetData.email !== ""
                          ? forgetData.email
                          : "your registered email address"}
                      </p>
                      <div className="signup-otp-box">
                        <input
                          type="password"
                          name="otp"
                          required={true}
                          value={forgetData.opt}
                          onChange={(e) => {
                            setForgetData({
                              ...forgetData,
                              opt: e.target.value,
                            });
                          }}
                        />
                        <label>OTP</label>
                      </div>
                      <div className="signup-otp-footer">
                        {loading ? (
                          loadingButton
                        ) : (
                          <button type="submit">Verify</button>
                        )}
                      </div>
                    </div>
                  )}
                  {forgotStage == 2 && (
                    <>
                      <div className="signup-user-box signup-password">
                        <input
                          type="password"
                          name="password"
                          required={true}
                          value={forgetData.password}
                          onChange={(e) => {
                            setForgetData({
                              ...forgetData,
                              password: e.target.value,
                            });
                          }}
                        />
                        <label className="required">Password</label>
                      </div>
                      <div className="signup-user-box signup-password">
                        <input
                          type="password"
                          name="confirmpass"
                          required={true}
                          value={forgetData.confirmPassword}
                          onChange={(e) => {
                            setForgetData({
                              ...forgetData,
                              confirmPassword: e.target.value,
                            });
                          }}
                        />
                        <label className="required">Confirm Password</label>
                      </div>
                      <div className="signup-otp-footer">
                        {loading ? (
                          loadingButton
                        ) : (
                          <button type="submit">Submit</button>
                        )}
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </>
        </div>
        <img
          src="/assets/img/login/astronaut.png"
          alt=""
          className="login-astronaut-image"
        />
        <img
          src="/assets/img/login/moon.png"
          alt=""
          className="login-moon-image"
        />
        {/* <img
          src="/assets/img/login/saturn.png"
          alt=""
          className="login-saturn-image"
        />
        <div className="login-star-images">
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
          <img src="/assets/img/login/star.png" alt="" />
        </div> */}
      </section>
    </>
  );
}
