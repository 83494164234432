import "../css/ProfileObjective.css";

export default function ObjectiveEvents({ title, status, message }) {
  return (
    <div className="profile-objective">
      <div
        className="profile-objective-icon"
        style={{
          color: status === 2 ? "green" : status === 1 ? "yellow" : "red",
        }}
      >
        <i
          className={`fa-solid fas ${
            status == 0
              ? "fa-xmark"
              : status == 1
              ? "fa-sticky-note"
              : "fa-check"
          }`}
        ></i>
      </div>
      <div className="info">
        <div className="title">{title}</div>
        <div
          className="status"
          style={{
            color:
              status === 2
                ? "green"
                : status === 1
                ? "rgba(255, 255, 0, 0.84)"
                : "#34b7f1",
          }}
        >
          {message}
        </div>
      </div>
    </div>
  );
}
