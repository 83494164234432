import { useState } from "react";
import MemberCard from "../components/TeamMemberCard";
import team from "../data/team.json";
import "../css/Team.css";

export default function Team() {
  let [contentCateg, setContentCateg] = useState(0);

  let categories = [
    // [0, "S.Coms", "5th Years", "4th Years"],
    [0, "Heads"],
    [1, "Sub-Heads"],
  ];

  const mapCategs = (categ) => {
    return (
      <div
        className={`team-page-cat ${
          contentCateg == categ[0] ? "team-page-cat-isactive" : ""
        }`}
        onClick={() => setContentCateg(categ[0])}
        key={categ[1]}
      >
        {categ[1]}
      </div>
    );
  };

  let content = (
    <>
      <div className="team-page-content-title">
        {categories[contentCateg][1]}
      </div>
      <div className="team-page-content-members">
        {team[categories[contentCateg][1].replace(" ", "-")].map(
          (data, ind) => (
            <MemberCard
              data={data}
              isSpecial={contentCateg === 0}
              key={categories[contentCateg][1] + ind}
            />
          )
        )}
      </div>
    </>
  );

  return (
    <div className="team-box">
      <div className="team-page">
        <div className="team-page-header">
          <b>Our Team</b>
          <div className="team-page-cat-select">
            {categories.map((ele) => mapCategs(ele))}
          </div>
        </div>
        <div className="team-page-content">{content}</div>
      </div>
    </div>
  );
}
